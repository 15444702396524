var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useContext, useEffect, useRef, useState, } from "react";
import { GlobalContextAPI } from "../../../context/GlobalContext";
import { OnClickOutsideDiv } from "../../../hooks";
import { InputLabel } from "../../common";
import { ChevronDown, ChevronUp } from "../../Icons";
import Checkbox from "../Checkbox";
export var MultipleSelectbox = function (_a) {
    var id = _a.id, _b = _a.options, options = _b === void 0 ? [] : _b, name = _a.name, label = _a.label, _c = _a.className, className = _c === void 0 ? "" : _c, width = _a.width, placeholder = _a.placeholder, value = _a.value, labelFieldset = _a.labelFieldset, onSelect = _a.onSelect, _d = _a.maxSelection, maxSelection = _d === void 0 ? undefined : _d;
    var snackbar = useContext(GlobalContextAPI).snackbar;
    var _e = useState(false), isFocus = _e[0], setIsFocus = _e[1];
    var _f = useState(false), showOption = _f[0], setShowOption = _f[1];
    var selectRef = useRef(null);
    var optionsRef = useRef({});
    var hideSelectOption = function () {
        setShowOption(false);
    };
    var getActiveOptionsData = options.map(function (item) {
        if (item.label === value)
            return __assign(__assign({}, item), { isSelected: true });
        return __assign(__assign({}, item), { isSelected: false });
    });
    var _g = useState([]), activeOption = _g[0], setActiveOptions = _g[1];
    useEffect(function () {
        setActiveOptions(getActiveOptionsData);
    }, [options]);
    var getSelectValue = function () {
        if (value)
            return value;
        return placeholder || "Select item";
    };
    var selectHandler = function (val, key) {
        var copyActiveOption = __spreadArray([], activeOption, true);
        copyActiveOption[key].isSelected = val;
        setActiveOptions(copyActiveOption);
        if (!onSelect)
            return;
        var activeSelectedOptions = copyActiveOption.filter(function (item) { return item.isSelected === true; });
        if (activeSelectedOptions.length > 0) {
            var activeLabel = activeSelectedOptions.map(function (item) { return item.label; });
            onSelect(activeLabel.join(","));
        }
        else {
            onSelect("");
        }
    };
    OnClickOutsideDiv(selectRef, hideSelectOption);
    var isColumnAlign = className && className.includes("flex-col");
    return (React.createElement("div", { className: "".concat(className, " relative w-full h-9"), ref: selectRef, role: "selectbox" },
        labelFieldset ? (React.createElement("div", { className: "absolute z-50 -top-2 text-xs font-medium  bg-inherit left-1.5 text-blue-152 bg-gradient-to-t from-white to-neutrals-EAE" }, labelFieldset)) : (!!label && (React.createElement("div", { className: "flex ".concat(isColumnAlign ? "" : "h-9", " items-center gap-1"), style: { width: isColumnAlign ? "100%" : "30%" } },
            React.createElement(InputLabel, { id: id || name, label: label })))),
        React.createElement("div", { className: "flex flex-col flex-1 relative", style: { width: width || "100%" } },
            React.createElement("div", { className: "w-full flex border rounded-lg items-center px-2 bg-white\n          ".concat(isFocus ? "ring-1 ring-blue-132" : "border-neutrals-D9D ", "\n          "), onClick: function () {
                    setShowOption(true);
                    setTimeout(function () {
                        var getOneSelectedItem = getSelectValue().split(",");
                        var selectedOption = options.findIndex(function (option) { return option.label === getOneSelectedItem[0]; });
                        if (optionsRef.current[selectedOption]) {
                            var target = optionsRef.current[selectedOption];
                            target.parentNode.scrollTop = target.offsetTop;
                        }
                    }, 0);
                }, role: "showOption" },
                React.createElement("input", { id: id || name, type: "text", name: name, className: "py-[10px] outline-none w-full cursor-pointer text-xs font-medium text-blue-132", value: getSelectValue(), readOnly: true, onFocus: function () { return setIsFocus(true); }, onBlur: function () { return setIsFocus(false); } }),
                !showOption ? React.createElement(ChevronDown, null) : React.createElement(ChevronUp, null)),
            showOption && (React.createElement("div", { className: "absolute w-full top-10 border rounded z-50 box-shadow max-h-48 overflow-auto" }, activeOption.map(function (val, key) {
                return (React.createElement("div", { key: key, ref: function (el) { return (optionsRef.current[key] = el); }, className: "flex gap-2 p-2 bg-white font-inter text-xs font-medium text-blue-132 hover:bg-blue-FOF hover:font-medium cursor-pointer last:border-b-0 border-b-2 border-b-slate-100 first:rounded-t last:rounded-b", role: "optiondiv".concat(val.label) },
                    React.createElement(Checkbox, { id: val.label, name: val.label, onSelect: function (value) {
                            if (maxSelection && value) {
                                maxSelection - 1 >= getSelectValue().split(",").length
                                    ? selectHandler(value, key)
                                    : snackbar.handler({
                                        message: "You are allowed to select ".concat(maxSelection, " items only."),
                                        type: "error",
                                        value: true,
                                    });
                            }
                            else
                                selectHandler(value, key);
                        }, value: val.isSelected }),
                    React.createElement("label", { htmlFor: val.label, className: "w-full cursor-pointer" }, val.label)));
            }))))));
};
