import React, { useRef, useState } from "react";
import { OnClickOutsideDiv } from "../../../hooks";
import { InputLabel } from "../../common";
import { ChevronDown, ChevronUp } from "../../Icons";
export var Selectbox = function (_a) {
    var id = _a.id, options = _a.options, name = _a.name, label = _a.label, labelCaption = _a.labelCaption, _b = _a.className, className = _b === void 0 ? "" : _b, width = _a.width, placeholder = _a.placeholder, labelFieldset = _a.labelFieldset, value = _a.value, onSelect = _a.onSelect;
    var _c = useState(false), showOption = _c[0], setShowOption = _c[1];
    var _d = useState(false), isFocus = _d[0], setIsFocus = _d[1];
    var selectRef = useRef(null);
    var refs = useRef({});
    var hideSelectOption = function () {
        setShowOption(false);
    };
    var getSelectValue = function () {
        if (value)
            return value;
        return placeholder || "Select item";
    };
    var selectHandler = function (val, key) {
        if (!onSelect)
            return;
        onSelect(val, key);
        setShowOption(false);
    };
    OnClickOutsideDiv(selectRef, hideSelectOption);
    var isColumnAlign = className && className.includes("flex-col");
    return (React.createElement("div", { className: "".concat(className, " relative w-full "), ref: selectRef, role: "selectbox", style: { width: width || "100%" } },
        labelFieldset ? (React.createElement("div", { className: "absolute z-50 -top-2 text-xs font-medium  bg-inherit left-1.5 text-blue-152 bg-gradient-to-t from-white to-neutrals-EAE" }, labelFieldset)) : (!!label && (React.createElement("div", { className: "flex ".concat(isColumnAlign ? "" : "h-9", " items-center gap-1 w-full") },
            React.createElement(InputLabel, { id: id || name, label: label })))),
        labelCaption && (React.createElement("div", { className: " text-xs font-inter font-normal text-blue-152 opacity-60" }, labelCaption)),
        React.createElement("div", { className: "flex flex-col flex-1 relative w-full" },
            React.createElement("div", { className: "w-full flex border rounded-lg items-center px-2 bg-white\n          ".concat(isFocus ? "ring-1 ring-blue-132" : "border-neutrals-D9D ", "\n          "), onClick: function () {
                    setShowOption(true);
                    setTimeout(function () {
                        var findSelectedIndex = options.findIndex(function (it) { return it.label === getSelectValue(); });
                        if (refs.current[findSelectedIndex]) {
                            var target = refs.current[findSelectedIndex];
                            target.parentNode.scrollTop = target.offsetTop;
                        }
                    }, 0);
                }, onKeyDown: function (e) {
                    e.key === "ArrowDown" && setShowOption(true);
                }, role: "showOption" },
                React.createElement("input", { id: id || name, type: "text", name: name, className: "py-[10px] outline-none w-full cursor-pointer text-xs font-medium text-blue-132", value: getSelectValue(), onFocus: function () { return setIsFocus(true); }, onBlur: function () { return setIsFocus(false); }, readOnly: true }),
                !showOption ? React.createElement(ChevronDown, null) : React.createElement(ChevronUp, null)),
            showOption && (React.createElement("div", { className: "absolute w-full top-10 border rounded z-50 box-shadow bg-white max-h-48 overflow-auto" }, options.map(function (val, key) {
                return (React.createElement("div", { ref: function (el) { return (refs.current[key] = el); }, key: key, className: "flex flex-wrap break-all p-2 font-inter text-xs font-medium  hover:text-blue-132 hover:bg-blue-FOF  cursor-pointer last:border-b-0 border-b-2 border-b-slate-100 first:rounded-t last:rounded-b\n                  ".concat(getSelectValue() === val.label
                        ? "bg-blue-132 text-white"
                        : "text-blue-132", "\n                  "), onClick: function () { return selectHandler(val, key); }, role: "optiondiv".concat(val.label) }, val.label));
            }))))));
};
