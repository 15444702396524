import React from "react";
export var Headings = function (_a) {
    var text = _a.text, _b = _a.className, className = _b === void 0 ? "" : _b, type = _a.type, _c = _a.captionType, captionType = _c === void 0 ? "default" : _c, caption = _a.caption;
    return (React.createElement("div", null,
        React.createElement("div", { className: "".concat(headingTypeMap[type], " ").concat(className, " select-none") }, text),
        caption && (React.createElement("div", { className: "absolute pointer-events-none text-blue-152 font-inter text-xs opacity-60", style: { whiteSpace: 'pre-line' } }, caption))));
};
var captionsTypeMap = {
    success: "text-green-47D",
    default: "text-blue-152",
    error: "text-red-FD0",
};
var headingTypeMap = {
    PageTitle: "font-equipE font-medium text-2xxl text-blue-132",
    CardTitleWrapper: "font-equipE font-normal text-lg text-blue-132",
    CardSubTitleWrapper: "text-xs font-inter font-normal text-blue-152",
    HelpText: "text-xs font-inter font-normal text-blue-152 opacity-60",
};
