var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import { Button, Radio, TextArea, TextField } from "../../FormComp";
import { PlusCircle } from "../../Icons";
import Modal from "../../Modal";
import { Headings } from "../Headings";
var TargetIPnSSHKey = function (_a) {
    var deployData = _a.deployData, stateSetter = _a.stateSetter;
    var _b = useState(false), showAddKeyModal = _b[0], setShowAddKeyModal = _b[1];
    var _c = useState({
        id: "",
        description: "",
        key: "",
    }), newKeyData = _c[0], setNewKeyData = _c[1];
    var _d = useState(""), selectedKey = _d[0], setSelectedKey = _d[1];
    var renderAddNewKeyBody = function () {
        return (React.createElement("div", { className: "flex flex-col w-full" },
            React.createElement(TextField, { name: "id", value: newKeyData.id, label: "ID", className: "flex flex-col gap-y-1 mb-2", onInput: function (val) { return setNewKeyData(__assign(__assign({}, newKeyData), { id: val })); } }),
            React.createElement(TextField, { name: "description", value: newKeyData.description, label: "Description", className: "flex flex-col gap-y-1  mb-2", onInput: function (val) { return setNewKeyData(__assign(__assign({}, newKeyData), { description: val })); } }),
            React.createElement(TextArea, { name: "key", value: newKeyData.key, label: "Key", height: 100, onInput: function (val) { return setNewKeyData(__assign(__assign({}, newKeyData), { key: val })); } }),
            React.createElement("div", { className: "mt-2 flex justify-end" },
                React.createElement(Button, { title: "Continue", type: "primary", size: "small", onClick: function () { return (setShowAddKeyModal(false),
                        setNewKeyData({ id: "", description: "", key: "" })); } }))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "mt-3 flex flex-col gap-y-4 lg:flex-row lg:gap-x-4" },
            React.createElement("div", { className: "bg-white rounded-xl w-2/5 p-4 flex flex-col" },
                React.createElement(Headings, { text: "Target IP Addresses", type: "CardTitleWrapper", className: "mb-2" }),
                React.createElement(TextArea, { className: "flex flex-col gap-2", name: "targetIP", label: "Enter a comma-separated list of worker IP addresses", value: deployData.targetIP, placeholder: "Example: 123.45.6789, 123.45.6789", onInput: function (val) { return stateSetter("targetIP", val); }, height: 180 })),
            React.createElement("div", { className: "bg-white rounded-xl w-3/5 p-4 flex flex-col" },
                React.createElement(Headings, { text: "SSH Private-key", type: "CardTitleWrapper", className: "mb-2" }),
                React.createElement("div", { className: "flex flex-col" },
                    React.createElement("div", { className: "flex flex-row gap-x-4 w-full" },
                        React.createElement("div", { className: "flex w-1/2 flex-col" },
                            React.createElement(Headings, { type: "CardSubTitleWrapper", text: "Add New Key" }),
                            React.createElement("div", { className: "mt-2 border border-dashed border-neutrals-EOE h-32 rounded text-blue-152 flex flex-col justify-center items-center bg-neutrals-EAE", onClick: function () { return setShowAddKeyModal(true); }, role: "openAddKeyModalbtn" },
                                React.createElement(PlusCircle, null),
                                React.createElement("div", { className: "font-inter font-normal text-xs text-blue-132" }, "Add New Key Here"))),
                        React.createElement("div", { className: "flex w-1/2 flex-col" },
                            React.createElement(Headings, { type: "CardSubTitleWrapper", text: "Select Existing Key" }),
                            React.createElement("div", { className: "border border-neutrals-EOE h-32 rounded mt-2 p-2 text-xs font-inter overflow-y-auto available-list bg-neutrals-EAE" }, [
                                "key1.pem",
                                "key2.pem",
                                "key3.pem",
                                "key4.pem",
                                "key5.pem",
                                "key6.pem",
                                "key7.pem",
                                "key8.pem",
                                "key9.pem",
                                "key10.pem",
                                "key12.pem",
                                "key13.pem",
                            ].map(function (val, index) {
                                return (React.createElement("div", { key: index, className: "p-1" },
                                    React.createElement(Radio, { id: val, name: "selectedKey", value: val, label: val, onSelect: function () { return (setSelectedKey(val), stateSetter("selectedKey", val)); }, checked: val === selectedKey })));
                            })))),
                    React.createElement("div", { className: "mt-4" },
                        React.createElement(TextField, { className: "flex items-center gap-2", label: "SSH Username", name: "sshUsername", value: deployData.sshUsername, width: 175, onInput: function (val) { return stateSetter("sshUsername", val); } }))))),
        showAddKeyModal && (React.createElement(Modal, { show: showAddKeyModal, size: "sm", header: true, title: "Add New Key", children: renderAddNewKeyBody() }))));
};
export default TargetIPnSSHKey;
export { TargetIPnSSHKey };
