import { createSlice } from "@reduxjs/toolkit";
export var RumSlice = createSlice({
    name: "rumStatus",
    initialState: {},
    reducers: {
        storeRumDetails: function (state, action) { return (state = action.payload); },
    },
});
export var storeRumDetails = RumSlice.actions.storeRumDetails;
export default RumSlice.reducer;
