var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _this = this;
import JSZip from "jszip";
// import { GetLastSuccessfulBuild } from "../services";
var isProd = process.env.REACT_APP_ENVIRONMENT === "production";
//compare Framework up and Framework down last Success build
// return false will disable build btn and vice versa ..
// pageName: FrameworkUp/FrameWorkDown
// const CompareLastSuccessBuild = async (
//   pageName: "FrameworkUp" | "FrameworkDown"
// ) => {
//   const { data: frameworkUpData, error: frameworkUpError } =
//     await GetLastSuccessfulBuild(Jobs.FRAMEWORK_UP);
//   const { data: frameworkDownData, error: frameworkDownError } =
//     await GetLastSuccessfulBuild(Jobs.FRAMEWORK_DOWN);
//   if (!frameworkUpError && !frameworkDownError) {
//     const frameworkUpSuccessTime = frameworkUpData.timestamp
//       ? new Date(frameworkUpData.timestamp)
//       : "no data";
//     const frameworkDownSuccessTime = frameworkDownData.timestamp
//       ? new Date(frameworkDownData.timestamp)
//       : "no data";
//     if (
//       frameworkDownSuccessTime !== "no data" &&
//       frameworkUpSuccessTime !== "no data"
//     ) {
//       if (frameworkUpSuccessTime > frameworkDownSuccessTime) {
//         return pageName === "FrameworkUp" ? true : false;
//       } else {
//         return pageName === "FrameworkUp" ? false : true;
//       }
//     } else {
//       //no framework build Success
//       if (
//         frameworkUpSuccessTime === "no data" &&
//         frameworkDownSuccessTime === "no data"
//       ) {
//         return pageName === "FrameworkUp" ? false : true;
//       }
//       // only frameworkup is success and there is no frameworkdown success build
//       else if (
//         frameworkUpSuccessTime !== "no data" &&
//         frameworkDownSuccessTime === "no data"
//       ) {
//         return pageName === "FrameworkUp" ? true : false;
//       }
//     }
//   }
// };
/**
 * This func handles supported file type
 * @param fileName
 * @param supportedFile
 * @returns boolean
 */
var FileValidator = function (fileName, supportedFile) {
    return supportedFile.name
        ? supportedFile.name === fileName
        : supportedFile.ext
            ? fileName.includes(supportedFile.ext)
            : true;
};
/**
 * This func returns uploaded file size
 * @param size
 * @returns size
 */
var FileSizeMapper = function (size) {
    return size > 0 && size <= 1000000
        ? "".concat((size / 1000).toFixed(2), " kb")
        : size > 1000000
            ? "".concat((size / 1000000).toFixed(2), " mb")
            : size;
};
/**
 * This func used to showing logs
 * @param message
 * @param optionalParams
 * @returns
 */
var $Log = function (message) {
    var optionalParams = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        optionalParams[_i - 1] = arguments[_i];
    }
    if (isProd)
        return;
    if (optionalParams.length === 0) {
        console.log(message);
        return;
    }
    console.log.apply(console, __spreadArray([message], optionalParams, false));
    return;
};
/**
 * This func used for form validation
 * @param args (pass required fields as args)
 * @returns (boolean)
 */
var FormValidator = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return !args.some(function (item) {
        return item.name !== undefined ? item.name.length === 0 : item.length === 0;
    });
};
// const FormValidator = (args: any) => {
//   let state = Object.values(args);
//   return !state.some((item: any) =>
//     item.name !== undefined ? item.name.length === 0 : item.length === 0
//   );
// };
/**
 * This func generated ranfom hex color code
 */
var RandomHexColorCode = function () {
    var n = (Math.random() * 0xfffff * 1000000).toString(16);
    return "#" + n.slice(0, 6);
};
/**
 * This func generates random number
 */
var getRandomNumber = function (limit) {
    return Math.floor(Math.random() * limit);
};
/**
 * This func generated ranfom hex color code
 */
var RandomDarkColorCode = function () {
    var h = getRandomNumber(360);
    var randomColor = "hsl(".concat(h, "deg, 50%, 50%)");
    return randomColor;
};
var IsInteger = function (x) {
    return Number.isInteger(x);
};
var NumberToFixed = function (val) {
    if (IsInteger(val)) {
        return val;
    }
    return val.toFixed(2);
};
var DateFormatter = function (value) {
    var date = new Date(value);
    if (date.toString() === "Invalid Date")
        return value;
    return new Intl.DateTimeFormat("pt-BR", {
        // dateStyle: "short",
        year: "2-digit",
        day: "2-digit",
        month: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        // timeStyle: "short",
        hour12: false,
    }).format(date);
};
var GetLocalStorageData = function (key) {
    var data = localStorage.getItem(key);
    return data;
};
var DeleteLocalStorageData = function (key) {
    if (localStorage.getItem(key)) {
        localStorage.removeItem(key);
    }
};
var RoundNum = function (num, length) {
    var number = Math.round(num * Math.pow(10, length)) / Math.pow(10, length);
    return number;
};
var ExtractZipFilesCount = function (file) { return __awaiter(void 0, void 0, void 0, function () {
    var jsZip, extractedFiles;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if ((file === null || file === void 0 ? void 0 : file.size) === 0)
                    return [2 /*return*/];
                jsZip = new JSZip();
                return [4 /*yield*/, jsZip.loadAsync(file)];
            case 1:
                extractedFiles = _a.sent();
                return [2 /*return*/, Object.keys(extractedFiles.files).length];
        }
    });
}); };
/**
 * Check whether zip file contain expected file extensions
 * @param file
 * @param expectedExt
 * @returns Boolean
 */
var ExtractAndCheckFileType = function (file, expectedExt) { return __awaiter(void 0, void 0, void 0, function () {
    var jsZip, extractedFiles, isValidFileUpload;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if ((file === null || file === void 0 ? void 0 : file.size) === 0)
                    return [2 /*return*/];
                jsZip = new JSZip();
                return [4 /*yield*/, jsZip.loadAsync(file)];
            case 1:
                extractedFiles = _a.sent();
                isValidFileUpload = Object.keys(extractedFiles.files).filter(function (file) {
                    return file.split(".").pop() !== expectedExt;
                });
                if (isValidFileUpload.length > 0) {
                    return [2 /*return*/, false];
                }
                return [2 /*return*/, true];
        }
    });
}); };
var Debouncing = function (func, timeout) {
    if (timeout === void 0) { timeout = 300; }
    var timer;
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        clearTimeout(timer);
        timer = setTimeout(function () { return func.apply.apply(func, __spreadArray([_this], args, false)); }, timeout);
    };
};
export * from "./chartUtils";
export { FileValidator, FileSizeMapper, $Log, FormValidator, RandomHexColorCode, IsInteger, NumberToFixed, DateFormatter, GetLocalStorageData, DeleteLocalStorageData, RoundNum, ExtractZipFilesCount, Debouncing, ExtractAndCheckFileType, RandomDarkColorCode, };
