var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { clearErrorPerMinutesData } from "../redux/Reducers/Reporting/Singledashboard/ErrorPerMinutesReducer";
import { clearVusersData } from "../redux/Reducers/Reporting/Singledashboard/RunningVUsersReducer";
import { clearThroughputData } from "../redux/Reducers/Reporting/Singledashboard/ThroughputReducer";
import { clearTransactionResponseTimeData } from "../redux/Reducers/Reporting/Singledashboard/TransactionResponseTimeReducer";
/**
 * This function handles data for comparison charts
 * @param arrA
 * @param arrB
 * @param chartA
 * @param chartB
 * @returns single array with compared data
 */
var ConstructChartData = function (arrA, arrB, chartA, chartB) {
    var mergeArr = __spreadArray(__spreadArray([], (arrA || []), true), arrB, true);
    var timeArr = Array.from(new Set(mergeArr.map(function (item) { return item.elapsedTime; })));
    var comb = [];
    var storeObj = [];
    timeArr.forEach(function (item1) {
        mergeArr.forEach(function (item2) {
            if (item1 === item2.elapsedTime) {
                storeObj.push(item2);
            }
        });
        comb.push(__assign({}, Object.assign.apply(Object, __spreadArray([{}], storeObj, false))));
        storeObj = [];
    });
    var chartData = comb.map(function (item) {
        var obj = __assign({}, item);
        if (!item.hasOwnProperty(chartA) || item[chartA] === null) {
            obj[chartA] = 0;
        }
        if (!item.hasOwnProperty(chartB) || item[chartB] === null) {
            obj[chartB] = 0;
        }
        return __assign({}, obj);
    });
    chartData.sort(function (a, b) {
        return a.elapsedTime > b.elapsedTime ? 1 : b.elapsedTime > a.elapsedTime ? -1 : 0;
    });
    return chartData;
};
/**
 * THis function handles time formatting for charts X axis
 * @param value
 * @returns converted time
 */
var XaxisDateFormatter = function (value) {
    var date = new Date(value);
    if (date.toString() === "Invalid Date")
        return value;
    return new Intl.DateTimeFormat("pt-BR", {
        // dateStyle: "short",
        // year: "",
        // day: "2-digit",
        // month: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "numeric",
        // timeStyle: "short",
        hour12: false,
    }).format(date);
};
//clear charts data from redux store
var RemoveChartsStoreDataHook = function (dispatcher) {
    dispatcher(clearErrorPerMinutesData());
    dispatcher(clearThroughputData());
    dispatcher(clearTransactionResponseTimeData());
    dispatcher(clearVusersData());
};
// getting min, max and random index from given array
var GetMinMaxValue = function (chartData) {
    var maxValue = Math.max.apply(Math, chartData === null || chartData === void 0 ? void 0 : chartData.map(function (o) { return o.Users; }));
    var minValue = Math.min.apply(Math, chartData === null || chartData === void 0 ? void 0 : chartData.map(function (o) { return o.Users; }));
    var maxIndex = chartData.findIndex(function (item) { return item.Users === maxValue; });
    var minIndex = chartData.findIndex(function (item) { return item.Users === minValue; });
    var randomIndexArray = Array.apply(null, new Array(1)).map(function () {
        var index = Math.floor(Math.random() * chartData.length);
        return [maxIndex, minIndex].includes(index)
            ? Math.floor(Math.random() * chartData.length)
            : index;
    });
    // const randomIndexArray = [maxIndex / 2];
    return { maxIndex: maxIndex, minIndex: minIndex, randomIndexArray: randomIndexArray };
};
export { ConstructChartData, XaxisDateFormatter, RemoveChartsStoreDataHook, GetMinMaxValue, };
