import React, { useContext, useRef } from "react";
import { GlobalContextAPI } from "../../../context/GlobalContext";
import { FileValidator, FileSizeMapper } from "../../../helpers";
import { InputLabel, Tooltip } from "../../common";
import { CrossX, FileIcon } from "../../Icons";
import { Headings } from "../../layouts";
export var TextFile = function (_a) {
    var id = _a.id, _b = _a.className, className = _b === void 0 ? "" : _b, label = _a.label, labelCaption = _a.labelCaption, toolTip = _a.toolTip, toolTipPosition = _a.toolTipPosition, name = _a.name, supportedFile = _a.supportedFile, file = _a.file, setFile = _a.setFile, fileIconType = _a.fileIconType, _c = _a.disable, disable = _c === void 0 ? false : _c, caption = _a.caption, captionType = _a.captionType;
    var snackbar = useContext(GlobalContextAPI).snackbar;
    var inputRef = useRef(null);
    var fileSelectHandler = function () {
        if (!inputRef || disable || file.name !== "")
            return;
        inputRef.current.value = null;
        inputRef.current.click();
    };
    var renderFileUploaded = (React.createElement("div", { className: "bg-transparent text-blue-132 flex items-center justify-between px-1" },
        React.createElement("div", { className: "flex items-center gap-1 " },
            FileTypeIconMap[fileIconType],
            file.name),
        React.createElement("div", { className: "flex items-center" },
            file.size ? "".concat(FileSizeMapper(file.size)) : "",
            React.createElement("div", { role: "removeFile", onClick: function () {
                    return !disable && setFile({ name: "", size: 0, content: "" });
                } },
                React.createElement(CrossX, null)))));
    var fileChangeHandler = function (file) {
        var fileReader = new FileReader();
        var validFile = supportedFile
            ? FileValidator(file.name, supportedFile)
            : true;
        if (validFile) {
            setFile(file);
            //   fileReader.onloadend = () => {
            //     // console.log(fileReader.result);
            //     let fileData = {
            //       name: file.name,
            //       size: file.size,
            //       content: fileReader.result,
            //     };
            //     setFile(fileData);
            //     snackbar.handler({
            //       message: `The file ‘${
            //         supportedFile?.name || file.name
            //       }’ uploaded successfully.`,
            //       type: "success",
            //       value: true,
            //     });
            //   };
            //   fileReader.readAsText(file);
        }
        else {
            snackbar.handler({
                message: "".concat(supportedFile.name
                    ? "Only files with the name '".concat(supportedFile.name, "' allowed.")
                    : "Only files with the file extension ".concat(supportedFile.ext, " allowed.")),
                type: "error",
                value: true,
            });
        }
    };
    return (React.createElement("div", { className: "flex ".concat(className, " w-full") },
        !!label && (React.createElement("div", { className: "flex items-center", style: { width: "30%" } },
            React.createElement(InputLabel, { id: id || name, label: label }),
            !!toolTip && React.createElement(Tooltip, { text: toolTip, position: toolTipPosition }))),
        labelCaption && (React.createElement(Headings, { type: "HelpText", text: labelCaption, className: "mb-1" })),
        React.createElement("div", { className: "flex-1" },
            React.createElement("div", { className: "border flex items-center rounded-lg w-full border-neutrals-D9D ".concat(disable ? "bg-neutrals-EAE" : "bg-white", " h-9") },
                React.createElement("span", { className: "text-xs flex items-center px-3 bg-neutrals-F4F text-blue-152 w-1/4 cursor-pointer h-full rounded-tl-lg rounded-bl-lg", onClick: fileSelectHandler, role: "fileSelect" }, "Choose File"),
                React.createElement("div", { className: "w-3/4 h-full ".concat(file.name ? "" : "flex items-center px-2", " font-inter text-xs font-normal text-blue-152") }, file.name ? renderFileUploaded : "No File Choosen")),
            React.createElement("input", { id: id || name, name: name, className: "hidden", type: "file", ref: inputRef, onChange: function (e) { return fileChangeHandler(e.target.files[0]); }, role: "fileinput" }),
            caption && (React.createElement("div", { className: "font-inter text-xs font-normal mt-1 ".concat(captionsTypeMap[captionType], " opacity-80") }, caption)))));
};
var FileTypeIconMap = {
    JS: FileIcon.JS,
    YAML: FileIcon.YAML,
    ZIP: FileIcon.ZIP,
};
var captionsTypeMap = {
    success: "text-green-47D",
    default: "text-blue-152",
    error: "text-red-FD0",
};
