import { useEffect } from "react";
import { Leave_Page_message } from "../locale";
/**
 * This function is used to check where mouse is clicked outside or inside div
 * @param ref
 * @param handler
 */
var OnClickOutsideDiv = function (ref, handler) {
    useEffect(function () {
        var listener = function (e) {
            var el = ref === null || ref === void 0 ? void 0 : ref.current;
            if (el && !el.contains(e.target)) {
                handler();
            }
        };
        window.addEventListener("mousedown", listener);
        return function () { return window.removeEventListener("mousedown", listener); };
    }, []);
};
/**
 * This func is used to show leave message before exit
 * @param onExit
 */
var onLeaveComponent = function (onExit) {
    useEffect(function () {
        function handler(e) {
            if (onExit) {
                e.preventDefault();
                if (e) {
                    e.returnValue = Leave_Page_message;
                }
                return Leave_Page_message;
            }
        }
        window.addEventListener("beforeunload", handler);
        return function () {
            window.removeEventListener("beforeunload", handler);
        };
    });
};
export { OnClickOutsideDiv, onLeaveComponent };
