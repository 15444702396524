var _a;
import { createSlice } from "@reduxjs/toolkit";
export var TransactionResponseTime = createSlice({
    name: "TransactionResponseTime",
    initialState: {},
    reducers: {
        storeTransactionResponseTimeData: function (state, action) { return action.payload.data; },
        clearTransactionResponseTimeData: function () {
            return {};
        },
    },
});
export var clearTransactionResponseTimeData = (_a = TransactionResponseTime.actions, _a.clearTransactionResponseTimeData), storeTransactionResponseTimeData = _a.storeTransactionResponseTimeData;
export default TransactionResponseTime.reducer;
