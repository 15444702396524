import { createSlice } from "@reduxjs/toolkit";
export var JmeterSlice = createSlice({
    name: "JmeterBuildData",
    initialState: {},
    reducers: {
        storeJmeterBuildData: function (state, action) { return (state = action.payload); },
    },
});
export var storeJmeterBuildData = JmeterSlice.actions.storeJmeterBuildData;
export default JmeterSlice.reducer;
