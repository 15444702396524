import { configureStore, combineReducers } from "@reduxjs/toolkit";
import RumReducer from "./Reducers/TestExecution/RumReducer";
import FrameworkUp from "./Reducers/TestSetup/Frameworkup";
import FrameworkDown from "./Reducers/TestSetup/FrameworkDown";
import JmeterReducer from "./Reducers/TestExecution/JmeterReducer";
// import SingleDashboardReducer from "./Reducers/Reporting/SingleDashboardReducer";
import ErrorPerMinutesReducer from "./Reducers/Reporting/Singledashboard/ErrorPerMinutesReducer";
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, } from "redux-persist";
import localforage from "localforage";
import { encryptTransform } from "redux-persist-transform-encrypt";
import RunningVUsersReducer from "./Reducers/Reporting/Singledashboard/RunningVUsersReducer";
import ThroughputReducer from "./Reducers/Reporting/Singledashboard/ThroughputReducer";
import TransactionResponseTimeReducer from "./Reducers/Reporting/Singledashboard/TransactionResponseTimeReducer";
var encryptor = encryptTransform({
    secretKey: "Super-Secret-Key-Photon-Redux-Store", //consider using the user's access token or session key as the secret key.
});
var persistConfig = {
    key: "root",
    version: 1,
    storage: localforage,
    transforms: [encryptor],
};
var reducer = combineReducers({
    FrameworkUpBuildStatus: FrameworkUp,
    FrameworkDownBuildStatus: FrameworkDown,
    JmeterBuildStatus: JmeterReducer,
    RumBuildStatus: RumReducer,
    ErrorPerMinutesData: ErrorPerMinutesReducer,
    RunningVusersData: RunningVUsersReducer,
    ThroughputData: ThroughputReducer,
    TransactionResponseTimeData: TransactionResponseTimeReducer,
});
var persistedReducer = persistReducer(persistConfig, reducer);
export var store = configureStore({
    reducer: persistedReducer,
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [PERSIST, FLUSH, REHYDRATE, PAUSE, PURGE, REGISTER],
            },
        });
    },
    devTools: process.env.NODE_ENV === "development", //enable dev tools only in development mode
});
