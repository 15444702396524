import React from "react";
var Button = function (_a) {
    var _b = _a.disabled, disabled = _b === void 0 ? false : _b, title = _a.title, _c = _a.type, type = _c === void 0 ? "default" : _c, onClick = _a.onClick, _d = _a.size, size = _d === void 0 ? "medium" : _d;
    var clickHandler = function () {
        if (!onClick)
            return;
        onClick();
    };
    return (React.createElement("button", { className: "rounded-lg font-medium h-9 ".concat(buttonSizeMap[size], " ").concat(disabled
            ? "cursor-not-allowed bg-neutrals-ABB text-blue-132"
            : "".concat(buttonTypeMap[type])), onClick: function () { return clickHandler(); }, disabled: disabled }, title));
};
var buttonSizeMap = {
    full: "w-full",
    large: "w-44",
    medium: "w-32",
    small: "w-24",
};
var buttonTypeMap = {
    primary: "bg-blue-132 text-white hover:shadow-btn-hover hover:shadow-blue-132 active:shadow-inner",
    danger: "bg-red-B60 text-white hover:shadow-btn-hover hover:shadow-red-B60 active:shadow-inner",
    default: "bg-white text-blue-132 border border-blue-132 hover:shadow-btn-hover hover:shadow-blue-132 active:shadow-inner",
    pass: "bg-green-00Bd border-green-00B",
    fail: "bg-red-C00D border-red-C00D"
};
export default Button;
export { Button };
