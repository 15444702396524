export const VectorDownIcon = () => {
  return (
    <svg
      width="10"
      height="5"
      viewBox="0 0 10 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.833984 0.333008L5.00065 4.49967L9.16732 0.333008H0.833984Z"
        fill="currentColor"
      />
      <path
        d="M0.833984 0.333008L5.00065 4.49967L9.16732 0.333008H0.833984Z"
        fill="currentColor"
        fillOpacity="0.25"
      />
    </svg>
  );
};
