export const VectorUpIcon = () => {
  return (
    <svg
      width="10"
      height="5"
      viewBox="0 0 10 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.833984 4.66699L5.00065 0.500325L9.16732 4.66699H0.833984Z"
        fill="currentColor"
      />
      <path
        d="M0.833984 4.66699L5.00065 0.500325L9.16732 4.66699H0.833984Z"
        fill="currentColor"
        fillOpacity="0.25"
      />
    </svg>
  );
};
