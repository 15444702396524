var _a;
import { createSlice } from "@reduxjs/toolkit";
export var RunningVUsers = createSlice({
    name: "RunningVUsers",
    initialState: {},
    reducers: {
        storeVusersData: function (state, action) { return action.payload.data; },
        clearVusersData: function () {
            return [];
        },
    },
});
export var clearVusersData = (_a = RunningVUsers.actions, _a.clearVusersData), storeVusersData = _a.storeVusersData;
export default RunningVUsers.reducer;
