export const PDFDownloadIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.18125 29.5625C7.63125 29.5625 7.16146 29.3677 6.77188 28.9781C6.38229 28.5885 6.1875 28.1187 6.1875 27.5687V5.43125C6.1875 4.88125 6.38229 4.41146 6.77188 4.02187C7.16146 3.63229 7.63125 3.4375 8.18125 3.4375H20.1094L26.8125 10.1406V27.5687C26.8125 28.1187 26.6177 28.5885 26.2281 28.9781C25.8385 29.3677 25.3687 29.5625 24.8187 29.5625H8.18125ZM19.3531 10.8281V4.98438H8.18125C8.06667 4.98438 7.96354 5.03021 7.87187 5.12187C7.78021 5.21354 7.73438 5.31667 7.73438 5.43125V27.5687C7.73438 27.6833 7.78021 27.7865 7.87187 27.8781C7.96354 27.9698 8.06667 28.0156 8.18125 28.0156H24.8187C24.9333 28.0156 25.0365 27.9698 25.1281 27.8781C25.2198 27.7865 25.2656 27.6833 25.2656 27.5687V10.8281H19.3531Z"
        fill="currentColor"
      />
      <path
        d="M11.4748 26.1246V24.6121H12.0248C12.403 24.6121 12.8911 24.5502 13.1936 24.4264C13.7986 24.1927 14.1355 23.6977 14.1355 22.9483C14.1355 22.1577 13.7505 21.7039 13.1867 21.5252C12.9186 21.4358 12.4923 21.3877 12.0386 21.3877H10.4023V26.1246H11.4748ZM11.4748 23.7252V22.3433H11.9973C12.2586 22.3433 12.5061 22.3639 12.6848 22.4396C12.9048 22.5289 13.0492 22.7283 13.0492 22.9964C13.0492 23.2989 12.9323 23.5052 12.6917 23.6152C12.5473 23.6839 12.3067 23.7252 12.0111 23.7252H11.4748Z"
        fill="currentColor"
      />
      <path
        d="M14.8201 26.1246H16.0232C16.7244 26.1246 17.2538 26.0489 17.6457 25.9114C18.6288 25.5677 19.1101 24.8046 19.1101 23.6839C19.1101 22.4808 18.5532 21.8552 17.6801 21.5664C17.3569 21.4564 16.7932 21.3877 16.1538 21.3877H14.8201V26.1246ZM15.8926 25.1689V22.3433H16.3257C16.8001 22.3433 17.0888 22.4052 17.3019 22.5014C17.7763 22.7077 18.0238 23.1752 18.0238 23.7114C18.0238 24.3371 17.7763 24.7358 17.4119 24.9489C17.1782 25.0864 16.7794 25.1689 16.2844 25.1689H15.8926Z"
        fill="currentColor"
      />
      <path
        d="M20.9481 26.1246V24.3096H22.6669V23.3539H20.9481V22.3433H22.88V21.3877H19.8756V26.1246H20.9481Z"
        fill="currentColor"
      />
      <path
        d="M16.9375 11.9273H15.8218V16.9356L13.6648 14.7786L12.8715 15.5719L16.4169 19.043L19.9375 15.5223L19.1441 14.7538L16.9375 16.9356V11.9273Z"
        fill="currentColor"
      />
    </svg>
  );
};
