import React, { useState } from "react";
import { InputLabel, Tooltip } from "../../common";
import { EyeIcon } from "../../Icons";
import { Headings } from "../../layouts";
var PasswordField = function (_a) {
    var id = _a.id, className = _a.className, _b = _a.disabled, disabled = _b === void 0 ? false : _b, caption = _a.caption, name = _a.name, label = _a.label, labelCaption = _a.labelCaption, value = _a.value, width = _a.width, toolTip = _a.toolTip, _c = _a.toolTipPosition, toolTipPosition = _c === void 0 ? "top" : _c, onInput = _a.onInput;
    var _d = useState(false), isFocus = _d[0], setIsFocus = _d[1];
    var _e = useState(false), togglePassword = _e[0], setTogglePassword = _e[1];
    var onChangeHandler = function (e) {
        if (!onInput)
            return;
        onInput(e.target.value);
    };
    var isColumnAlign = className && className.includes("flex-col");
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: className },
            !!label && (React.createElement("div", { className: "flex ".concat(isColumnAlign ? "" : "h-9", " items-center gap-1 w-full") },
                React.createElement(InputLabel, { id: id, label: label }),
                !!toolTip && React.createElement(Tooltip, { text: toolTip, position: toolTipPosition }))),
            labelCaption && (React.createElement(Headings, { type: "HelpText", text: labelCaption, className: "mb-1 font-inter text-xs opacity-60" })),
            React.createElement("div", { className: "flex border text-xs rounded-lg w-full items-center relative h-9 ".concat(width ? "" : "flex-1", "\n          ").concat(disabled ? "bg-neutrals-EAE" : "bg-white", "\n          ").concat(isFocus ? "border-blue-132" : "border-neutrals-D9D ", "\n          "), style: { width: width || "100%" } },
                React.createElement("input", { name: name, value: value, onChange: onChangeHandler, className: "p-2 flex-grow flex-shrink font-inter flex-1 min-w-0 outline-none bg-opacity-0 bg-transparent placeholder:text-neutrals-D9D disabled:text-neutrals-D9D", autoComplete: "off", disabled: disabled, type: !togglePassword ? "password" : "text", onFocus: function () { return setIsFocus(true); }, onBlur: function () { return setIsFocus(false); }, role: "passwordField" }),
                React.createElement("span", { className: "".concat(!togglePassword ? "text-transparent" : "", " mx-1 cursor-pointer"), onClick: function () { return setTogglePassword(!togglePassword); }, role: "showPassword" },
                    React.createElement(EyeIcon, null)))),
        caption && (React.createElement("div", { className: "font-inter text-xs text-blue-152b font-normal mt-1" }, caption))));
};
var toolTipPosition = {
    top: "top",
    right: "right",
    bottom: "bottom",
};
export default PasswordField;
export { PasswordField };
