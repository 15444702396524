import React from "react";
export const ChevronDown = () => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.306999 0.443322C0.445888 0.33221 0.598667 0.273182 0.765333 0.266238C0.932 0.259293 1.07783 0.318322 1.20283 0.443322L4.8695 4.10999L8.557 0.422488C8.66811 0.311377 8.81742 0.259293 9.00492 0.266238C9.19242 0.273182 9.34172 0.33221 9.45283 0.443322C9.59172 0.58221 9.65769 0.731515 9.65075 0.891238C9.6438 1.05096 9.57783 1.19332 9.45283 1.31832L5.307 5.46415C5.23756 5.5336 5.16811 5.58221 5.09867 5.60999C5.02922 5.63777 4.95283 5.65165 4.8695 5.65165C4.78617 5.65165 4.70978 5.63777 4.64033 5.60999C4.57089 5.58221 4.50144 5.5336 4.432 5.46415L0.306999 1.33915C0.181999 1.21415 0.122971 1.06832 0.129916 0.901654C0.13686 0.734988 0.195888 0.58221 0.306999 0.443322Z"
        fill="currentColor"
      />
    </svg>
  );
};
