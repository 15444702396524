export var retryLazy = function (componentImport, name) {
    var componentKey = "retry-".concat(name, "-refreshed");
    return new Promise(function (resolve, reject) {
        // check if window has already refreshed
        var hasRefreshed = JSON.parse(window.sessionStorage.getItem(componentKey) || "false");
        //import component
        componentImport()
            .then(function (component) {
            window.sessionStorage.setItem(componentKey, "false"); // success , so reset the refresh
            resolve(component);
        })
            .catch(function (error) {
            if (!hasRefreshed) {
                // not refreshed
                window.localStorage.setItem(componentKey, "true"); // to be removed
                window.sessionStorage.setItem(componentKey, "true"); // now we are goind to reload the component
                // console.log(`${componentKey} is reloaded`); // this needs to be removed
                return window.location.reload();
            }
            reject(error);
        });
    });
};
