export const CSVIcon = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.18125 29.5625C7.63125 29.5625 7.16146 29.3677 6.77188 28.9781C6.38229 28.5885 6.1875 28.1187 6.1875 27.5687V5.43125C6.1875 4.88125 6.38229 4.41146 6.77188 4.02187C7.16146 3.63229 7.63125 3.4375 8.18125 3.4375H20.1094L26.8125 10.1406V27.5687C26.8125 28.1187 26.6177 28.5885 26.2281 28.9781C25.8385 29.3677 25.3687 29.5625 24.8187 29.5625H8.18125ZM19.3531 10.8281V4.98438H8.18125C8.06667 4.98438 7.96354 5.03021 7.87187 5.12187C7.78021 5.21354 7.73438 5.31667 7.73438 5.43125V27.5687C7.73438 27.6833 7.78021 27.7865 7.87187 27.8781C7.96354 27.9698 8.06667 28.0156 8.18125 28.0156H24.8187C24.9333 28.0156 25.0365 27.9698 25.1281 27.8781C25.2198 27.7865 25.2656 27.6833 25.2656 27.5687V10.8281H19.3531Z"
        fill="currentColor"
      />
      <path
        d="M8.18125 29.5625C7.63125 29.5625 7.16146 29.3677 6.77188 28.9781C6.38229 28.5885 6.1875 28.1187 6.1875 27.5687V5.43125C6.1875 4.88125 6.38229 4.41146 6.77188 4.02187C7.16146 3.63229 7.63125 3.4375 8.18125 3.4375H20.1094L26.8125 10.1406V27.5687C26.8125 28.1187 26.6177 28.5885 26.2281 28.9781C25.8385 29.3677 25.3687 29.5625 24.8187 29.5625H8.18125ZM19.3531 10.8281V4.98438H8.18125C8.06667 4.98438 7.96354 5.03021 7.87187 5.12187C7.78021 5.21354 7.73438 5.31667 7.73438 5.43125V27.5687C7.73438 27.6833 7.78021 27.7865 7.87187 27.8781C7.96354 27.9698 8.06667 28.0156 8.18125 28.0156H24.8187C24.9333 28.0156 25.0365 27.9698 25.1281 27.8781C25.2198 27.7865 25.2656 27.6833 25.2656 27.5687V10.8281H19.3531Z"
        fill="currentColor"
        fillOpacity="0.25"
      />
      <path
        d="M11 15.8125C11 15.0531 11.6156 14.4375 12.375 14.4375H28.875C29.6344 14.4375 30.25 15.0531 30.25 15.8125V21.3125C30.25 22.0719 29.6344 22.6875 28.875 22.6875H12.375C11.6156 22.6875 11 22.0719 11 21.3125V15.8125Z"
        fill="currentColor"
      />
      <path
        d="M17.7986 20.8668V19.6211H17.7656C17.3531 20.0006 16.7756 20.2646 16.1239 20.2646C15.0761 20.2646 14.2346 19.7036 14.2346 18.4331C14.2346 17.2533 15.1091 16.6758 16.1156 16.6758C16.6849 16.6758 17.3614 16.8986 17.7409 17.2946H17.7739V16.0158C17.4356 15.6941 16.7261 15.5043 15.9919 15.5043C14.4161 15.5043 12.9229 16.5768 12.9229 18.4661C12.9229 20.3058 14.2429 21.4278 15.9424 21.4278C16.6849 21.4278 17.4521 21.1721 17.7986 20.8668Z"
        fill="white"
      />
      <path
        d="M18.5307 20.8256C18.9762 21.2051 19.8177 21.4278 20.5684 21.4278C22.2184 21.4278 22.8289 20.5533 22.8289 19.6376C22.8289 18.8208 22.4659 18.3176 21.2944 17.9546L20.5437 17.7236C19.9909 17.5586 19.8837 17.4018 19.8837 17.1626C19.8837 16.8656 20.0487 16.6263 20.7169 16.6263C21.2862 16.6263 22.0617 16.8491 22.4989 17.2533H22.5319V15.9663C22.2267 15.6941 21.4182 15.5043 20.8489 15.5043C19.3392 15.5043 18.5802 16.2633 18.5802 17.2451C18.5802 18.0701 19.0092 18.6393 19.9909 18.9198L20.7004 19.1261C21.2697 19.2828 21.5254 19.4231 21.5254 19.7366C21.5254 20.1656 21.1954 20.2811 20.6344 20.2811C20.0322 20.2811 19.1494 20.0583 18.5637 19.5138H18.5307V20.8256Z"
        fill="white"
      />
      <path
        d="M26.3843 21.3123L28.6366 15.6281H27.2341C26.2276 18.4413 26.0708 18.8868 25.8646 19.4891C25.6501 18.8621 25.5098 18.4496 24.4538 15.6281H23.0266L25.2706 21.3123H26.3843Z"
        fill="white"
      />
    </svg>
  );
};
