import React, { useEffect, useRef, useState } from "react";
var CircularProgressbar = function (_a) {
    var status = _a.status, startedAt = _a.startedAt;
    var _b = useState(0), second = _b[0], setSecond = _b[1];
    var _c = useState(0), minute = _c[0], setMinute = _c[1];
    var _d = useState(0), hour = _d[0], setHour = _d[1];
    /**
     * compare with job started time and current time
     */
    useEffect(function () {
        var TimeNow = new Date();
        var StartedTime = startedAt ? new Date(startedAt) : new Date();
        var diffTimeMs = TimeNow.getTime() - StartedTime.getTime();
        var diffHrs = Math.floor(diffTimeMs / 3600000); // hours
        var diffMins = Math.round(diffTimeMs / 60000) % 60; // minutes
        var diffSeconds = Math.round(diffTimeMs / 1000) % 60; // seconds
        setSecond(diffSeconds);
        setMinute(diffMins);
        setHour(diffHrs);
    }, [startedAt]);
    var interval = useRef(null);
    useEffect(function () {
        timerFunction();
        return function () { return clearInterval(interval.current); };
    }, []);
    useEffect(function () {
        if (minute === 59 && second === 59) {
            setHour(function (prev) { return prev + 1; });
            setMinute(0);
            setSecond(0);
        }
        else if (second === 59) {
            setMinute(function (prev) { return prev + 1; });
            setSecond(0);
        }
    }, [second]);
    if (status !== "START") {
        clearInterval(interval.current);
    }
    var timerFunction = function () {
        interval.current = setInterval(function () {
            setSecond(function (prev) { return prev + 1; });
        }, 1000);
    };
    return (React.createElement("div", { className: "relative" },
        React.createElement("div", { className: "loader w-16 h-16 border-6 ".concat(statusWiseBorderColor[status], " ").concat(status === "START" && "border-t-blue-132", " rounded-full") }),
        React.createElement("div", { className: "absolute text-blue-132 font-equipE font-medium loader-innner-text" }, "".concat(minute < 10 ? "0".concat(minute) : minute, ":").concat(second < 10 ? "0".concat(second) : second))));
};
var statusWiseBorderColor = {
    START: "border-neutral-300",
    SUCCESS: "border-green-47D",
    ABORTED: "border-red-B60",
    FAILURE: "border-red-B60",
    ERROR: "border-red-B60",
};
export default CircularProgressbar;
export { CircularProgressbar };
