export const ApiDataCsv = () => {
    return (
        <svg width="33" height="33" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9 43C11.1 43 10.4167 42.7167 9.85 42.15C9.28333 41.5833 9 40.9 9 40.1V7.9C9 7.1 9.28333 6.41667 9.85 5.85C10.4167 5.28333 11.1 5 11.9 5H29.25L39 14.75V40.1C39 40.9 38.7167 41.5833 38.15 42.15C37.5833 42.7167 36.9 43 36.1 43H11.9ZM28.15 15.75V7.25H11.9C11.7333 7.25 11.5833 7.31667 11.45 7.45C11.3167 7.58333 11.25 7.73333 11.25 7.9V40.1C11.25 40.2667 11.3167 40.4167 11.45 40.55C11.5833 40.6833 11.7333 40.75 11.9 40.75H36.1C36.2667 40.75 36.4167 40.6833 36.55 40.55C36.6833 40.4167 36.75 40.2667 36.75 40.1V15.75H28.15Z" fill="currentColor" />
            <rect x="16" y="21" width="28" height="12" rx="2" fill="currentColor" />
            <path d="M20.894 31H19L23.0885 22H24.8389L28.9274 31H26.9681L25.9231 28.7271H21.939L20.894 31ZM22.5269 27.2511H25.3222C24.3033 24.9782 24.1727 24.6386 23.9376 24.0377C23.6894 24.6386 23.5588 24.939 22.5269 27.2511Z" fill="white" />
            <path d="M31.7559 31H30.0185V22H33.4409C34.4598 22 35.2958 22.1176 35.8836 22.2874C37.0069 22.627 37.7384 23.4891 37.7384 24.8215C37.7384 26.2453 37.0853 27.238 35.7529 27.656C35.139 27.852 34.2508 27.9434 33.4801 27.9434H31.7559V31ZM31.7559 26.3759H33.5585C34.29 26.3759 34.8125 26.2845 35.1651 26.1538C35.6876 25.9448 35.9228 25.5138 35.9228 24.8999C35.9228 24.3774 35.6093 23.9724 35.0998 23.8026C34.6557 23.6589 34.1463 23.6197 33.5454 23.6197H31.7559V26.3759Z" fill="white" />
            <path d="M41.0679 31H39.3306V22H41.0679V31Z" fill="white" />
        </svg>
    )
}