var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import LoginBanner from "../../../public/image/LoginBanner.png";
import { PhotonLogoIcon } from "../../components/Icons";
import { Button, PasswordField, TextField } from "../../components/FormComp";
/**
 * Note: Kindly correct the Login route
 *  Other component is also rendered in background
 */
var LoginPage = function () {
    var _a = useState({
        username: "",
        password: "",
    }), userDetails = _a[0], setUserDetails = _a[1];
    return (React.createElement("div", { className: "flex h-full" },
        React.createElement("div", { className: "w-1/2 relative select-none min-w-[400px]" },
            React.createElement("div", { className: "absolute top-20 left-24 flex items-center" },
                React.createElement(PhotonLogoIcon, null),
                React.createElement("div", { className: "font-medium text-2xxl font-equipE text-white" }, "Photon")),
            React.createElement("div", { className: "absolute bottom-20 left-24 " },
                React.createElement("div", { className: "scale-[2] absolute -top-16 left-2" },
                    React.createElement(PhotonLogoIcon, null)),
                React.createElement("div", { className: "font-equipE font-light italic text-4xl \n          text-transparent \n          bg-clip-text \n          bg-gradient-to-b  from-green-47D to-[#2b7877]" },
                    React.createElement("div", null, "End-to-end"),
                    React.createElement("div", null, "performance testing "),
                    React.createElement("div", null, "solution"))),
            React.createElement("img", { src: LoginBanner, className: "h-full w-full", alt: "LoginBanner" })),
        React.createElement("div", { className: "w-1/2 min-w-[400px]" },
            React.createElement("div", { className: "flex justify-center flex-col h-full " },
                React.createElement("div", { className: "rounded-[10px] lg:mx-28 md:mx-16 bg-white px-12 py-14" },
                    React.createElement("div", { className: "text-blue-152 text-[28px] font-bold font-equipE select-none" }, "User Login"),
                    React.createElement(TextField, { name: "username", value: userDetails.username, label: "Username", onInput: function (val) {
                            return setUserDetails(__assign(__assign({}, userDetails), { username: val }));
                        } }),
                    React.createElement(PasswordField, { name: "password", value: userDetails.password, label: "Password", className: "mb-4", onInput: function (val) {
                            return setUserDetails(__assign(__assign({}, userDetails), { password: val }));
                        } }),
                    React.createElement("div", { className: "select-none" },
                        React.createElement(Button, { title: "Login", type: "primary", size: "full" })))))));
};
export default LoginPage;
