import React from "react";
import { Button } from "../../FormComp";
import { Page404Icon } from "../../Icons";
var NotFound = function () {
    var handleRedirect = function () {
        var _a;
        if (window) {
            window.location.href = (_a = window.location) === null || _a === void 0 ? void 0 : _a.origin;
        }
        return;
    };
    return (React.createElement("div", { className: "flex items-center justify-center w-full h-full" },
        React.createElement("div", { className: "flex flex-col items-center" },
            React.createElement(Page404Icon, null),
            React.createElement("div", { className: "text-md font-medium" }, "The Page you are looking for doesn't exist."),
            React.createElement(Button, { title: "Back to Home", onClick: function () { return handleRedirect(); }, type: "primary" }))));
};
export default NotFound;
