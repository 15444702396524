import React, { useState } from "react";
import { InputLabel, Tooltip } from "../../common";
import { Headings } from "../../layouts";
var TextField = function (_a) {
    var id = _a.id, className = _a.className, _b = _a.disabled, disabled = _b === void 0 ? false : _b, caption = _a.caption, name = _a.name, label = _a.label, labelCaption = _a.labelCaption, value = _a.value, width = _a.width, toolTip = _a.toolTip, _c = _a.toolTipPosition, toolTipPosition = _c === void 0 ? "top" : _c, _d = _a.placeholder, placeholder = _d === void 0 ? "" : _d, _e = _a.captionType, captionType = _e === void 0 ? "default" : _e, onInput = _a.onInput, onBlur = _a.onBlur;
    var _f = useState(false), isFocus = _f[0], setIsFocus = _f[1];
    var onChangeHandler = function (e) {
        if (!onInput)
            return;
        onInput(e.target.value);
    };
    var isColumnAlign = className && className.includes("flex-col");
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: className },
            !!label && (React.createElement("div", { className: "flex ".concat(isColumnAlign ? "" : "h-9", " items-center gap-1 w-full") },
                React.createElement(InputLabel, { id: id || name, label: label }),
                !!toolTip && React.createElement(Tooltip, { text: toolTip, position: toolTipPosition }))),
            labelCaption && (React.createElement(Headings, { type: "HelpText", text: labelCaption, className: "mb-1 font-inter text-xs opacity-60" })),
            React.createElement("div", { className: "w-full flex-1" },
                React.createElement("div", { className: "flex border text-xs rounded-lg w-full items-center relative h-9 ".concat(width ? "" : "flex-1", "\n          ").concat(disabled ? "bg-neutrals-EAE" : "bg-white", "\n          ").concat(isFocus ? "ring-1 ring-blue-132" : "border-neutrals-D9D ", "\n          "), style: { width: width || "100%" } },
                    React.createElement("input", { id: id || name, name: name, value: value, onChange: onChangeHandler, className: "p-2 flex-grow flex-shrink font-inter flex-1 min-w-0 outline-none bg-opacity-0 bg-transparent placeholder:text-neutrals-D9D disabled:text-blue-132", autoComplete: "off", disabled: disabled, type: "text", onFocus: function () { return setIsFocus(true); }, onBlur: function () {
                            setIsFocus(false);
                            if (onBlur) {
                                onBlur();
                            }
                        }, "aria-autocomplete": "none" }),
                    placeholder && value.length === 0 && (React.createElement("div", { className: "absolute pointer-events-none pl-2 text-blue-152 font-medium opacity-60" }, placeholder))),
                caption && (React.createElement("div", { className: "font-inter text-xs font-normal mt-1 ".concat(captionsTypeMap[captionType], " opacity-80") }, caption))))));
};
var toolTipPosition = {
    top: "top",
    right: "right",
    bottom: "bottom",
};
var captionsTypeMap = {
    success: "text-green-47D",
    default: "text-blue-152",
    error: "text-red-FD0",
};
export default TextField;
export { TextField };
