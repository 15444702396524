import { createSlice } from "@reduxjs/toolkit";
export var FrameworkDownSlice = createSlice({
    name: "FrameworkDownStatus",
    initialState: {},
    reducers: {
        storeFrameworkDownBuildDetails: function (state, action) { return (state = action.payload); },
    },
});
export var storeFrameworkDownBuildDetails = FrameworkDownSlice.actions.storeFrameworkDownBuildDetails;
export default FrameworkDownSlice.reducer;
