const ArrowRightGreen = () => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.704 1.30568C10.6116 1.20878 10.5006 1.13145 10.3777 1.07826C10.2548 1.02507 10.1224 0.997108 9.98852 0.996034C9.8546 0.994961 9.72183 1.0208 9.59809 1.07201C9.47435 1.12322 9.36215 1.19877 9.26816 1.29417C9.17417 1.38956 9.1003 1.50287 9.05093 1.62736C9.00156 1.75185 8.9777 1.88499 8.98076 2.01887C8.98383 2.15276 9.01375 2.28467 9.06876 2.40677C9.12377 2.52887 9.20275 2.63868 9.301 2.72968L14.67 8.02168H1C0.734784 8.02168 0.48043 8.12703 0.292893 8.31457C0.105357 8.50211 0 8.75646 0 9.02168C0 9.28689 0.105357 9.54125 0.292893 9.72878C0.48043 9.91632 0.734784 10.0217 1 10.0217H14.665L9.3 15.3067C9.12061 15.4947 9.02145 15.7452 9.02353 16.005C9.02561 16.2649 9.12877 16.5137 9.31116 16.6988C9.49354 16.884 9.74083 16.9908 10.0006 16.9967C10.2604 17.0027 10.5123 16.9073 10.703 16.7307L17.628 9.90868C17.7459 9.79239 17.8396 9.65382 17.9035 9.50104C17.9674 9.34825 18.0003 9.18429 18.0003 9.01868C18.0003 8.85306 17.9674 8.6891 17.9035 8.53632C17.8396 8.38353 17.7459 8.24497 17.628 8.12868L10.703 1.30568H10.704Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowRightGreen;
export { ArrowRightGreen };
