import React from "react";
import "./style.scss";
var Loader = function () {
    return (React.createElement("div", { className: "w-full h-full absolute flex items-center justify-center overflow-hidden left-0 right-0 top-0 bottom-0 z-10 bg-white bg-opacity-60 text-blue-152" },
        React.createElement("span", { className: "sr-only" }, "Loading"),
        React.createElement("div", { className: "relative inline-block w-24 h-24 loadingdots-parent" },
            React.createElement("div", null),
            React.createElement("div", null),
            React.createElement("div", null),
            React.createElement("div", null))));
};
export { Loader };
