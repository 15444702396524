var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { CircularProgressbar, Loader, LoadingDots, } from "../../../components/common";
import ProgressBar from "../../../components/ProgressBar";
import { GetBuildConsoleText, GetBuildStatus } from "../../../services";
import { Button } from "../../../components/FormComp";
import { Headings } from "../../../components/layouts";
import { AxiosTokenSource } from "../../../lib/axios";
import { useMutation } from "@tanstack/react-query";
// import { useDispatch } from "react-redux";
// import { storeRumDetails } from "../../../redux/Reducers/TestExecution/RumReducer";
// import { storeFrameworkDownBuildDetails } from "../../../redux/Reducers/TestSetup/FrameworkDown";
// import { storeFrameworkBuildDetails } from "../../../redux/Reducers/TestSetup/Frameworkup";
import { GlobalContextAPI } from "../../../context/GlobalContext";
import { AbortBuild } from "../../../services/TestExecution";
import Modal from "../../Modal";
var FrameworkBuild = function (_a) {
    var _b, _c;
    var jobName = _a.jobName, title = _a.title;
    var snackbar = useContext(GlobalContextAPI).snackbar;
    var _d = useState(""), buildLogs = _d[0], setBuildLogs = _d[1];
    var _e = useState(true), showDots = _e[0], setShowDots = _e[1];
    var _f = useState(false), disableAbortBtn = _f[0], setDisableAbortBtn = _f[1];
    var _g = useState(false), showAbortModal = _g[0], setShowAbortModal = _g[1];
    var _h = useState(false), fetchLoader = _h[0], setFetchLoader = _h[1];
    var _j = useState("START"), buildStatus = _j[0], setBuildStatus = _j[1];
    var _k = useState({}), buildDetails = _k[0], setBuildDetails = _k[1];
    // const dispatchToStore = useDispatch();
    var interval = useRef(null);
    var statusInterval = useRef(null);
    var axiosInstance = useRef(null);
    var showDivRef = useRef(null);
    var jobStatus = "";
    var isAbortClicked = useRef(null);
    useEffect(function () {
        localStorage.setItem("navigationAllowed", "true");
        axiosInstance.current = AxiosTokenSource.source();
        getBuildData();
        interval.current = setInterval(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2 /*return*/, getBuildData()];
        }); }); }, 6000);
        // getBuildStatus();
        return function () {
            axiosInstance.current.cancel();
            clearInterval(interval.current);
            clearInterval(statusInterval.current);
        };
    }, []);
    /**
     * User should see logs page even if build is success
     * call getBuildStatus to get status from API and store this
     * in localStorage
     */
    useEffect(function () {
        storeStatusToLocalStorage();
    }, []);
    useEffect(function () {
        var _a;
        // 👇️ scroll to bottom every time messages change
        (_a = showDivRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: "smooth" });
    }, [buildLogs]);
    var storeStatusToLocalStorage = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, error;
        var _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    setFetchLoader(true);
                    return [4 /*yield*/, GetBuildStatus(frameworkBuildNameMap[jobName])];
                case 1:
                    _a = _e.sent(), data = _a.data, error = _a.error;
                    if (!error && data) {
                        //store complete status details
                        setBuildDetails(data);
                        localStorage.setItem("build_details", JSON.stringify({
                            jobName: jobName,
                            allowedToBuild: (_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.allowToBuild,
                            JobStatus: (_c = data.data) === null || _c === void 0 ? void 0 : _c.status,
                            estimateDuration: (_d = data.data) === null || _d === void 0 ? void 0 : _d.estimatedDuration,
                        }));
                    }
                    setFetchLoader(false);
                    return [2 /*return*/];
            }
        });
    }); };
    //get status of framework ,to execute in every 10s until status for allowed to build is true
    // const getBuildStatus = async () => {
    //   statusInterval.current = setInterval(async () => {
    //     const { data, error } = await GetBuildStatus(
    //       frameworkBuildNameMap[jobName]
    //     );
    //     if (!error && data) {
    //       jobStatus = data.data.status || "";
    //       //store data to redux store
    //       dispatchToStore(dispatchToStoreMap[jobName](data));
    //       if (
    //         data.data.allowToBuild ||
    //         data.data.status === "COMPLETED" ||
    //         data.data.status === "FAILED"
    //       ) {
    //         clearInterval(statusInterval.current);
    //         return;
    //       }
    //     }
    //   }, 10000);
    // };
    var getBuildData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, error, startPos, getStatus, beStatus;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, GetBuildConsoleText(jobName, axiosInstance.current)];
                case 1:
                    _a = _b.sent(), data = _a.data, error = _a.error;
                    if (!error && data) {
                        setBuildLogs(data);
                        if (data.lastIndexOf("Finished: SUCCESS") > 0 ||
                            data.lastIndexOf("Finished: ABORTED") > 0 ||
                            data.lastIndexOf("Finished: FAILURE") > 0 ||
                            data.lastIndexOf("Finished: ERROR") > 0 ||
                            (jobStatus.length > 0 &&
                                jobStatus !== "INPROGRESS" &&
                                isAbortClicked.current === null)) {
                            startPos = -1;
                            if (data.lastIndexOf("Finished: SUCCESS") !== -1) {
                                startPos = data.lastIndexOf("Finished: SUCCESS");
                            }
                            else if (data.lastIndexOf("Finished: FAILURE") !== -1) {
                                startPos = data.lastIndexOf("Finished: FAILURE");
                            }
                            else if (data.lastIndexOf("Finished: ERROR") !== -1) {
                                startPos = data.lastIndexOf("Finished: ERROR");
                            }
                            else if (data.lastIndexOf("Finished: ABORTED") !== -1) {
                                startPos = data.lastIndexOf("Finished: ABORTED");
                            }
                            getStatus = "";
                            if (startPos !== -1) {
                                getStatus = data.slice(startPos + 10).trim();
                            }
                            else {
                                beStatus = {
                                    COMPLETED: "SUCCESS",
                                    FAILED: "FAILURE",
                                    ABORTED: "ABORTED",
                                    ERROR: "FAILURE",
                                };
                                getStatus = beStatus[jobStatus] || "FAILURE";
                            }
                            setBuildStatus(getStatus);
                            clearInterval(interval.current);
                            //clear old status API and run status API to store latest status in localStorage
                            // clearInterval(statusInterval.current);
                            storeStatusToLocalStorage();
                            setShowDots(false);
                            isAbortClicked.current = null;
                        }
                    }
                    else if (error && error.field !== "API CANCELLED") {
                        snackbar.handler({
                            message: React.createElement(React.Fragment, null, (error === null || error === void 0 ? void 0 : error.message) || "Server Error"),
                            type: "error",
                            value: true,
                        });
                        // setBuildStatus("ERROR");
                        // setBuildLogs(error?.message);
                        setShowDots(false);
                        clearInterval(interval.current);
                        // clearInterval(statusInterval.current);
                        storeStatusToLocalStorage();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var _l = useMutation(["AbortJob"], AbortBuild), isLoading = _l.isLoading, mutate = _l.mutate;
    // this function handles aborting of the jobs
    var abortJobHandler = function () {
        isAbortClicked.current = true;
        setShowAbortModal(false);
        setDisableAbortBtn(true);
        mutate(jobName);
    };
    var renderFrameworkMessage = useMemo(function () {
        var result = {
            START: (React.createElement("div", { className: "text-blue-132" },
                mapJobNameText[jobName],
                " build in progress.")),
            ABORTED: (React.createElement("div", { className: "text-red-FD0" },
                mapJobNameText[jobName],
                " build stopped.")),
            FAILURE: (React.createElement("div", { className: "text-red-FD0" },
                mapJobNameText[jobName],
                " build failed.")),
            SUCCESS: (React.createElement("div", { className: "text-green-47D" },
                mapJobNameText[jobName],
                " build successfully.")),
            ERROR: (React.createElement("div", { className: "text-red-FD0" },
                mapJobNameText[jobName],
                " build failed.")),
        };
        return result[buildStatus];
    }, [buildStatus]);
    return (React.createElement("div", { className: "w-full h-full p-5 relative" },
        React.createElement("div", { className: "flex md:flex-row md:justify-between flex-col items-center" },
            React.createElement(Headings, { text: title, type: "PageTitle" }),
            jobName.toString() !== "PHOTON_Framework_Up" &&
                jobName.toString() !== "PHOTON_Framework_Down" &&
                ((_b = buildDetails === null || buildDetails === void 0 ? void 0 : buildDetails.data) === null || _b === void 0 ? void 0 : _b.status) === "INPROGRESS" && (React.createElement("div", null,
                React.createElement(Button, { title: "Stop Test", type: "danger", onClick: function () { return setShowAbortModal(true); }, disabled: disableAbortBtn })))),
        React.createElement("div", { className: "bg-white rounded-xl p-4 mt-2" },
            React.createElement("div", { className: "flex lg:justify-between md:flex-col lg:flex-row" },
                React.createElement("div", null,
                    React.createElement("div", { className: "text-lg font-equipE" }, renderFrameworkMessage),
                    buildStatus === "START" && jobName === "PHOTON_Framework_Up" && (React.createElement("div", { className: "text-base mt-4 font-inter" }, "It will be up soon"))),
                React.createElement("div", { className: "w-16 h-16 mr-4" },
                    React.createElement(CircularProgressbar, { status: buildStatus, startedAt: (_c = buildDetails === null || buildDetails === void 0 ? void 0 : buildDetails.data) === null || _c === void 0 ? void 0 : _c.started_at }))),
            buildStatus === "START" && buildDetails.data && (React.createElement("div", { className: "my-4" },
                React.createElement(ProgressBar, { estimateData: buildDetails.data, jobName: jobName }))),
            React.createElement("div", { className: "text-xs" },
                React.createElement("div", { className: "text-sm text-blue-152 mb-2 font-equipE " }, "Console Output Log"),
                React.createElement("div", { className: "border border-neutrals-D9D rounded h-auto" },
                    React.createElement("div", { id: "p-wrap", className: "whitespace-pre-wrap break-all overflow-y-auto p-2\n              ".concat(showDots
                            ? "lg:h-[calc(100vh_-_21rem)] md:h-[calc(100vh_-_23rem)]"
                            : "lg:h-[calc(100vh_-_17.5rem)] md:h-[calc(100vh_-_19.5rem)]", " \n              ") },
                        buildLogs,
                        showDots && React.createElement(LoadingDots, null),
                        React.createElement("div", { ref: showDivRef }))))),
        showAbortModal && (React.createElement(Modal, { children: React.createElement("div", { className: "w-full" },
                React.createElement("div", { className: "text-sm" },
                    React.createElement("p", null,
                        "Do you want to stop",
                        " ",
                        React.createElement("span", { className: "font-medium" }, mapJobNameText[jobName]),
                        " ",
                        "job?")),
                React.createElement("div", { className: "flex justify-end gap-1 mt-4" },
                    React.createElement(Button, { title: "Close", size: "small", onClick: function () { return setShowAbortModal(false); } }),
                    React.createElement(Button, { title: "Stop", type: "danger", size: "small", onClick: abortJobHandler }))), show: showAbortModal, header: true, title: "Stop Test", size: "sm" })),
        (fetchLoader || isLoading) && React.createElement(Loader, null)));
};
export default FrameworkBuild;
export { FrameworkBuild };
var frameworkBuildNameMap = {
    PHOTON_Test_Execution: "/testExecute/status",
    PHOTON_Framework_Down: "/mainFramework/down/status",
    PHOTON_Framework_Up: "/mainFramework/up/status",
    PHOTON_Real_User_Monitoring: "/realUserMonitoring/status",
};
// const dispatchToStoreMap = {
//   PHOTON_Test_Execution: storeRumDetails,
//   PHOTON_Framework_Down: storeFrameworkDownBuildDetails,
//   PHOTON_Framework_Up: storeFrameworkBuildDetails,
//   PHOTON_Real_User_Monitoring: storeRumDetails,
// } as any;
var mapJobNameText = {
    PHOTON_Test_Execution: "JMeter",
    PHOTON_Framework_Down: "Infra Down",
    PHOTON_Framework_Up: "Infra Up",
    PHOTON_Real_User_Monitoring: "Real user monitoring",
};
