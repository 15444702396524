var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { Button } from "../../FormComp";
import { SomethingWentWrongIcon } from "../../Icons";
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            hasError: false,
        };
        return _this;
    }
    ErrorBoundary.getDerivedStateFromError = function () {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    };
    ErrorBoundary.prototype.render = function () {
        if (this.state.hasError) {
            return (React.createElement("div", { className: "flex items-center justify-center w-full" },
                React.createElement("div", { className: "flex flex-col items-center gap-1" },
                    React.createElement(SomethingWentWrongIcon, null),
                    React.createElement("div", { className: "text-2xl" }, "Something went wrong."),
                    React.createElement("div", { className: "text-md" }, "Reloading the page may fix this."),
                    React.createElement(Button, { title: "Reload", onClick: function () { return window.location.reload(); } }),
                    React.createElement(Button, { title: "Return to Home", onClick: function () { return (window.location.href = window.location.origin); } }))));
        }
        return this.props.children;
    };
    return ErrorBoundary;
}(React.Component));
export default ErrorBoundary;
