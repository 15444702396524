const ChevronRight = () => {
  return (
    <svg
      width="9"
      height="16"
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.92051 1.76519C7.98817 1.69359 8.04107 1.60936 8.07618 1.51731C8.11129 1.42527 8.12793 1.32721 8.12514 1.22873C8.12236 1.13025 8.1002 1.03329 8.05994 0.943375C8.01969 0.853461 7.96211 0.772356 7.89051 0.704693C7.8189 0.637029 7.73467 0.584131 7.64263 0.54902C7.55058 0.513908 7.45252 0.49727 7.35404 0.500056C7.25557 0.502842 7.1586 0.524996 7.06869 0.565256C6.97878 0.605515 6.89767 0.663089 6.83001 0.734693L0.455007 7.48469C0.323355 7.62394 0.25 7.80831 0.25 7.99994C0.25 8.19158 0.323355 8.37594 0.455007 8.51519L6.83001 15.2659C6.89722 15.3391 6.97831 15.3982 7.06856 15.4398C7.1588 15.4813 7.25641 15.5046 7.3557 15.5081C7.455 15.5116 7.554 15.4954 7.64697 15.4603C7.73994 15.4253 7.82501 15.3721 7.89726 15.3039C7.9695 15.2356 8.02746 15.1538 8.06779 15.0629C8.10811 14.9721 8.12999 14.8742 8.13215 14.7749C8.13432 14.6756 8.11672 14.5768 8.08039 14.4843C8.04406 14.3918 7.98971 14.3075 7.92051 14.2362L2.03151 7.99994L7.92051 1.76519V1.76519Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default ChevronRight;
export { ChevronRight };
