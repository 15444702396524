export const PhotonLogoIcon = () => {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9551 17.5323C13.9248 19.6541 10.8609 21.0773 8.37043 21.6751C7.12363 21.9744 6.04668 22.0605 5.2009 21.9422C4.3561 21.824 3.79221 21.5112 3.47496 21.0582C3.15771 20.6051 3.0567 19.9682 3.23456 19.134C3.41263 18.2987 3.86187 17.3162 4.56954 16.2469C5.98311 14.1111 8.36844 11.7187 11.3987 9.59694C14.4289 7.47514 17.4929 6.05192 19.9833 5.45408C21.2301 5.15478 22.3071 5.06869 23.1528 5.18704C23.9976 5.30524 24.5615 5.61797 24.8788 6.07105C25.196 6.52413 25.297 7.16098 25.1192 7.99525C24.9411 8.83051 24.4919 9.81307 23.7842 10.8823C22.3706 13.0181 19.9853 15.4105 16.9551 17.5323Z"
        stroke="#47D7AC"
      />
      <path
        d="M16.9547 9.59695C19.9849 11.7187 22.3702 14.1111 23.7838 16.2469C24.4915 17.3162 24.9407 18.2987 25.1188 19.134C25.2966 19.9683 25.1956 20.6051 24.8784 21.0582C24.5611 21.5113 23.9972 21.824 23.1524 21.9422C22.3067 22.0605 21.2297 21.9745 19.9829 21.6752C17.4925 21.0773 14.4285 19.6541 11.3983 17.5323C8.36804 15.4105 5.98271 13.0181 4.56914 10.8823C3.86147 9.81308 3.41223 8.83052 3.23416 7.99527C3.0563 7.16099 3.15731 6.52414 3.47456 6.07107C3.79181 5.61799 4.3557 5.30525 5.2005 5.18705C6.04628 5.06871 7.12323 5.1548 8.37003 5.45409C10.8605 6.05193 13.9244 7.47516 16.9547 9.59695Z"
        stroke="#2D807B"
      />
      <path
        d="M19.0202 13.5646C19.0202 17.2639 18.4286 20.59 17.4899 22.973C17.0199 24.166 16.4727 25.0975 15.8906 25.7225C15.3093 26.3467 14.7296 26.6292 14.1765 26.6292C13.6234 26.6292 13.0438 26.3467 12.4624 25.7225C11.8804 25.0975 11.3332 24.166 10.8632 22.973C9.92447 20.59 9.3329 17.2639 9.3329 13.5646C9.3329 9.86539 9.92447 6.53922 10.8632 4.15625C11.3332 2.96327 11.8804 2.0317 12.4624 1.40675C13.0438 0.782534 13.6234 0.5 14.1765 0.5C14.7296 0.5 15.3093 0.782534 15.8906 1.40675C16.4727 2.0317 17.0199 2.96327 17.4899 4.15625C18.4286 6.53922 19.0202 9.86539 19.0202 13.5646Z"
        stroke="#3AAB94"
      />
    </svg>
  );
};
