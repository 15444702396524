import React from "react";

export const ChevronUp = () => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.306877 5.55668C0.445766 5.66779 0.598544 5.72682 0.765211 5.73376C0.931878 5.74071 1.07771 5.68168 1.20271 5.55668L4.86938 1.89001L8.55688 5.57751C8.66799 5.68862 8.81729 5.74071 9.00479 5.73376C9.19229 5.72682 9.3416 5.66779 9.45271 5.55668C9.5916 5.41779 9.65757 5.26848 9.65063 5.10876C9.64368 4.94904 9.57771 4.80668 9.45271 4.68168L5.30688 0.535846C5.23743 0.466401 5.16799 0.41779 5.09854 0.390012C5.0291 0.362235 4.95271 0.348346 4.86938 0.348346C4.78604 0.348346 4.70966 0.362235 4.64021 0.390012C4.57077 0.41779 4.50132 0.466401 4.43188 0.535846L0.306877 4.66085C0.181877 4.78585 0.122849 4.93168 0.129794 5.09835C0.136738 5.26501 0.195766 5.41779 0.306877 5.55668V5.55668Z"
        fill="currentColor"
      />
    </svg>
  );
};
