import React from "react";
import ReactDOM from "react-dom";
import "./styles/global.scss";
import "../src/styles/fonts.css";
import App from "./App";
import { GlobalContext } from "./context/GlobalContext";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

let persistor = persistStore(store);
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <GlobalContext>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </GlobalContext>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
