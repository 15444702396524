import React from "react";
var Checkbox = function (_a) {
    var id = _a.id, name = _a.name, label = _a.label, _b = _a.value, value = _b === void 0 ? false : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, onSelect = _a.onSelect;
    var handleChange = function (value, e) {
        if (!disabled)
            onSelect(value, e);
    };
    return (React.createElement("div", { className: "inline-flex items-center" },
        React.createElement("input", { id: id, type: "checkbox", name: name, checked: value, className: "".concat(value ? "accent-blue-132" : "").concat(disabled ? " disabled:bg-neutrals-ABB" : "", " cursor-pointer"), onChange: function (e) { return handleChange(!value, e); }, disabled: disabled }),
        label && (React.createElement("label", { className: "ml-3 ".concat(disabled ? "text-neutrals-ABB" : "text-blue-152"), htmlFor: id }, label))));
};
export default Checkbox;
export { Checkbox };
