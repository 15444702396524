export const EditPencilIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5251 2.47485C18.0842 3.03402 18.3983 3.79241 18.3983 4.58318C18.3983 5.37396 18.0842 6.13235 17.5251 6.69152L7.55172 16.6665C7.32101 16.8973 7.03405 17.0639 6.71922 17.1498L2.45589 18.3123C2.34963 18.3412 2.23764 18.3414 2.13126 18.3131C2.02487 18.2847 1.92786 18.2288 1.85005 18.1509C1.77224 18.073 1.71639 17.9759 1.68815 17.8695C1.65991 17.7631 1.66028 17.6511 1.68922 17.5449L2.85172 13.2815C2.93764 12.9667 3.10424 12.6797 3.33505 12.449L13.3084 2.47485C13.8676 1.91569 14.6259 1.60156 15.4167 1.60156C16.2075 1.60156 16.9659 1.91569 17.5251 2.47485ZM12.5001 5.04985L4.21839 13.3332C4.14155 13.4101 4.08611 13.5058 4.05755 13.6107L3.18255 16.819L6.39089 15.944C6.49538 15.9147 6.59044 15.8587 6.66672 15.7815L14.9501 7.49985L12.5001 5.04985ZM14.1917 3.35818L13.3834 4.16652L15.8334 6.61652L16.6417 5.80818C16.9612 5.48218 17.1391 5.04326 17.1368 4.58681C17.1345 4.13036 16.9522 3.69326 16.6294 3.37049C16.3066 3.04773 15.8695 2.86538 15.4131 2.86308C14.9566 2.86077 14.5177 3.0387 14.1917 3.35818Z"
        fill="currentColor"
      />
    </svg>
  );
};
