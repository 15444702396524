export const Info = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99997 1.33301C11.6826 1.33301 14.668 4.31834 14.668 8.00101C14.668 11.683 11.6826 14.6683 7.99997 14.6683C4.3173 14.6683 1.33197 11.683 1.33197 8.00101C1.33197 4.31834 4.3173 1.33301 7.99997 1.33301ZM7.9973 6.83301C7.83393 6.83323 7.67632 6.89344 7.5544 7.0022C7.43249 7.11097 7.35476 7.26071 7.33597 7.42301L7.3313 7.50034L7.3333 11.1683L7.33797 11.2463C7.35676 11.4089 7.43471 11.5589 7.55696 11.6677C7.67922 11.7766 7.83723 11.8366 8.0009 11.8365C8.16457 11.8363 8.32246 11.7759 8.44449 11.6669C8.56652 11.5578 8.64417 11.4076 8.66264 11.245L8.66664 11.167L8.66464 7.49967L8.65997 7.42167C8.6407 7.25951 8.56258 7.11007 8.44043 7.00168C8.31829 6.8933 8.16061 6.83351 7.9973 6.83367V6.83301ZM7.99997 4.33367C7.77869 4.33367 7.56648 4.42158 7.41001 4.57804C7.25354 4.73451 7.16564 4.94673 7.16564 5.16801C7.16564 5.38929 7.25354 5.6015 7.41001 5.75797C7.56648 5.91444 7.77869 6.00234 7.99997 6.00234C8.22125 6.00234 8.43347 5.91444 8.58993 5.75797C8.7464 5.6015 8.8343 5.38929 8.8343 5.16801C8.8343 4.94673 8.7464 4.73451 8.58993 4.57804C8.43347 4.42158 8.22125 4.33367 7.99997 4.33367Z"
        fill="currentColor"
      />
    </svg>
  );
};
