import axios from "axios";
const API_URL = process.env.PUBLIC_BASE_API_URL;
export const AxiosTokenSource = axios.CancelToken;
const $Axios = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: `Basic ${window.btoa("Animesh:Animesh")}`,
  },
  // timeout: 20000, // increased bcoz triggering the build takes time
  // cancelToken: cancelTokenSource.token,
});
$Axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

$Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalError = error;
    if (axios.isCancel(error)) {
      originalError.field = "API CANCELLED";
    }
    return Promise.reject(originalError);
  }
);

export default $Axios;
