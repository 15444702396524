var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import $Axios from "../../lib/axios";
/**
 * Jmeter Build API Handler
 * @param jmeterFormData
 * @returns
 */
var BuildJmeterExecution = function (jmeterFormData) { return __awaiter(void 0, void 0, void 0, function () {
    var scriptFile, slaveCount, testDataFile, testType, userCount, testName, acceptanceID, otherFile, data, response;
    var _a, _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                scriptFile = jmeterFormData.scriptFile, slaveCount = jmeterFormData.slaveCount, testDataFile = jmeterFormData.testDataFile, testType = jmeterFormData.testType, userCount = jmeterFormData.userCount, testName = jmeterFormData.testName, acceptanceID = jmeterFormData.acceptanceID, otherFile = jmeterFormData.otherFile;
                data = new FormData();
                data.append("Jmeter_Slave_Pods_Count", slaveCount);
                data.append("Script", scriptFile);
                data.append("Locust_Runtime", "500000");
                data.append("Locust_Users", "0");
                data.append("Locust_Spawn_Rate", "10");
                data.append("TestData", ((_a = testDataFile === null || testDataFile === void 0 ? void 0 : testDataFile.name) === null || _a === void 0 ? void 0 : _a.length) > 0 ? "Yes" : "No");
                ((_b = testDataFile === null || testDataFile === void 0 ? void 0 : testDataFile.name) === null || _b === void 0 ? void 0 : _b.length) > 0 && data.append("CSV_Files", testDataFile);
                data.append("name", testName);
                data.append("user_count", userCount);
                data.append("test_type", testType);
                data.append("Other_Files_CSV", ((_c = otherFile === null || otherFile === void 0 ? void 0 : otherFile.name) === null || _c === void 0 ? void 0 : _c.length) > 0 ? "Yes" : "No");
                ((_d = otherFile === null || otherFile === void 0 ? void 0 : otherFile.name) === null || _d === void 0 ? void 0 : _d.length) > 0 && data.append("Other_Files", otherFile);
                acceptanceID !== null && data.append("acceptance_criteria_id", acceptanceID);
                return [4 /*yield*/, $Axios
                        .post("/testExecute", data, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                        .then(function (res) { return res.data; })];
            case 1:
                response = _e.sent();
                return [2 /*return*/, response];
        }
    });
}); };
/**
 * Jmeter status API handler
 * @returns
 */
var GetJmeterStatus = function () { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, $Axios
                    .get("testExecute/status")
                    .then(function (res) { return res.data; })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
        }
    });
}); };
/**
 * testExecute List API handler
 * @param signal
 * @returns
 */
var getTestExecutedList = function (signal) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, $Axios
                    .get("testExecute/list", { signal: signal })
                    .then(function (res) { return res.data.data; })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
        }
    });
}); };
/**
 * RUM build API handler
 * @param rumData
 * @returns
 */
var BuildRealUserMonitoring = function (rumData) { return __awaiter(void 0, void 0, void 0, function () {
    var browser, appUrl, iteration, scriptFile, data, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                browser = rumData.browser, appUrl = rumData.appUrl, iteration = rumData.iteration, scriptFile = rumData.scriptFile;
                data = new FormData();
                data.append("Browser", browser);
                appUrl.length > 0 && data.append("URL", appUrl);
                data.append("Iterations", iteration);
                data.append("Script_File", (scriptFile === null || scriptFile === void 0 ? void 0 : scriptFile.name.length) > 0 ? "Yes" : "No");
                (scriptFile === null || scriptFile === void 0 ? void 0 : scriptFile.name.length) > 0 && data.append("Script", scriptFile);
                return [4 /*yield*/, $Axios
                        .post("/realUserMonitoring", data, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                        .then(function (res) { return res.data.data; })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
        }
    });
}); };
/**
 * Abort Jobs Api handler
 * @param buildName
 * @returns
 */
var AbortBuild = function (buildName) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, $Axios
                    .post("/job/abort/".concat(buildName))
                    .then(function (res) { return res.data; })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
        }
    });
}); };
/**
 * API for Acceptance criteria POST
 */
var PostAcceptanceCriteria = function (formData) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, $Axios
                    .post("/acceptanceCriteria", formData)
                    .then(function (res) { return res.data; })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
        }
    });
}); };
/**
 * fetch acceptance criteria API
 */
var GetAcceptanceCriteria = function () { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, $Axios
                    .get("/acceptanceCriteria")
                    .then(function (res) { return res.data; })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
        }
    });
}); };
/**
 * Delete Acceptance criteria API
 */
var DeleteAcceptanceCriteria = function (id) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, $Axios
                    .delete("/acceptanceCriteria/".concat(id))
                    .then(function (res) { return res.data; })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
        }
    });
}); };
export { BuildJmeterExecution, GetJmeterStatus, getTestExecutedList, BuildRealUserMonitoring, AbortBuild, PostAcceptanceCriteria, GetAcceptanceCriteria, DeleteAcceptanceCriteria, };
