import React from "react";
import { CrossIcon } from "../Icons";
var Modal = function (_a) {
    var _b = _a.header, header = _b === void 0 ? false : _b, _c = _a.title, title = _c === void 0 ? "" : _c, show = _a.show, _d = _a.size, size = _d === void 0 ? "md" : _d, _e = _a.close, close = _e === void 0 ? false : _e, children = _a.children, setCloseModal = _a.setCloseModal;
    return (React.createElement("div", { className: "h-full w-full bg-black flex fixed top-0 left-0 z-50 bg-opacity-40 justify-center items-center ".concat(show ? "" : "hidden"), role: "Modal" },
        React.createElement("div", { className: "w-full max-h-full" },
            React.createElement("div", { className: "h-full flex flex-col overflow-hidden items-center w-full animateModal" },
                React.createElement("div", { className: "rounded flex flex-col bg-white p-4 ".concat(dialogSizeMap[size], " max-w-full") },
                    header && (React.createElement("div", { className: "flex justify-between w-full items-center mb-2" },
                        React.createElement("div", { className: "font-equipE font-normal text-lg text-red-800" }, title),
                        close &&
                            React.createElement("button", { onClick: setCloseModal },
                                React.createElement(CrossIcon, null)))),
                    React.createElement("div", { className: "flex" }, children))))));
};
var dialogSizeMap = {
    sm: "w-dialog-sm",
    md: "w-dialog-md",
    lg: "w-dialog-lg",
    xl: "w-dialog-xl",
};
export default Modal;
