import React from "react";
import { Tooltip } from "../../common";
export var Radio = function (_a) {
    var id = _a.id, name = _a.name, label = _a.label, checked = _a.checked, value = _a.value, disabled = _a.disabled, toolTip = _a.toolTip, onSelect = _a.onSelect;
    var onChangeHandler = function (value) {
        if (!onSelect)
            return;
        onSelect(value);
    };
    return (React.createElement("div", { className: "flex items-center gap-2" },
        React.createElement("input", { value: value, id: id, type: "radio", name: name, checked: !!checked, disabled: !!disabled, className: "accent-blue-132 ", onChange: function () { return onChangeHandler(!checked); } }),
        !!label && (React.createElement("div", { className: "flex items-center gap-1" },
            React.createElement("label", { htmlFor: id, className: "font-inter text-xs text-blue-152" }, label),
            toolTip ? React.createElement(Tooltip, { text: toolTip, position: "right" }) : React.createElement(React.Fragment, null)))));
};
