import React from "react";
import { InputLabel, Tooltip } from "../../common";
var TextArea = function (_a) {
    var id = _a.id, className = _a.className, disabled = _a.disabled, inputClassName = _a.inputClassName, name = _a.name, label = _a.label, toolTip = _a.toolTip, toolTipPosition = _a.toolTipPosition, placeholder = _a.placeholder, value = _a.value, 
    // width,
    height = _a.height, onInput = _a.onInput;
    var onChangeHandler = function (e) {
        if (onInput) {
            onInput(e.target.value);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: className },
            !!label && (React.createElement("div", { className: "flex items-center gap-1" },
                React.createElement(InputLabel, { id: id, label: label }),
                !!toolTip && React.createElement(Tooltip, { text: toolTip, position: toolTipPosition }))),
            React.createElement("div", { className: "flex border border-neutrals-EOE w-full overflow-y-auto rounded font-inter text-xs text-blue-152 p-2", style: {
                    height: height || 200,
                } },
                React.createElement("textarea", { name: name, className: "flex-grow flex-shrink min-w-0 resize-none outline-none ".concat(inputClassName, " available-list bg-transparent ").concat(disabled ? "" : ""), 
                    // rows={10}
                    placeholder: placeholder, value: value, onChange: onChangeHandler, autoComplete: "off", disabled: disabled })))));
};
export default TextArea;
export { TextArea };
