import React, { useState, lazy, Suspense, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { Loader } from "../components/common";
import { useHistory } from "react-router-dom";
import LoginPage from "../pages/Login";
import { retryLazy } from "./util";
const ErrorBoundary = lazy(() =>
  retryLazy(
    () => import("../components/layouts/ErrorBoundary"),
    "ErrorBoundary"
  )
);
const NotFound = lazy(() =>
  retryLazy(() => import("../components/layouts/NotFound/index"), "NotFound")
);
const Header = lazy(() =>
  retryLazy(() => import("../components/Header"), "Header")
);
const Sidebar = lazy(() =>
  retryLazy(() => import("../components/Sidebar"), "Sidebar")
);
const SidebarCollapser = lazy(() =>
  retryLazy(
    () => import("../components/Sidebar/SidebarCollapser"),
    "SidebarCollapser"
  )
);
const DashBoardPage = lazy(() =>
  retryLazy(() => import("../pages/Dashboard/DashBoard"), "DashBoardPage")
);
const TestSetup = lazy(() =>
  retryLazy(() => import("../pages/TestSetup"), "TestSetup")
);
const DeployTelegraf = lazy(() =>
  retryLazy(() => import("../pages/TestSetup/DeployTelegraf"), "DeployTelegraf")
);
const DeployPromethus = lazy(() =>
  retryLazy(
    () => import("../pages/TestSetup/DeployPrometheus"),
    "DeployPromethus"
  )
);
const FrameworkUp = lazy(() =>
  retryLazy(
    () => import("../pages/TestSetup/FrameworkUp/Frameworkup"),
    "FrameworkUp"
  )
);
const FrameworkupBuild = lazy(() =>
  retryLazy(
    () => import("../pages/TestSetup/FrameworkUp/FrameworkupBuild"),
    "FrameworkupBuild"
  )
);
const FrameworkDown = lazy(() =>
  retryLazy(() => import("../pages/TestSetup/FrameworkDown"), "FrameworkDown")
);
const FrameworkDownBuild = lazy(() =>
  retryLazy(
    () => import("../pages/TestSetup/FrameworkDown/FrameworkDownBuild"),
    "FrameworkDownBuild"
  )
);
const TestExecution = lazy(() =>
  retryLazy(() => import("../pages/TestExecution"), "TestExecution")
);

const RealUserMonitoring = lazy(() =>
  retryLazy(
    () => import("../pages/TestExecution/RealUserMonitoring"),
    "RealUserMonitoring"
  )
);
const RealUserMonitoringBuild = lazy(() =>
  retryLazy(
    () =>
      import(
        "../pages/TestExecution/RealUserMonitoring/RealUserMonitoringBuild"
      ),
    "RealUserMonitoringBuild"
  )
);

const Jmeter = lazy(() =>
  retryLazy(() => import("../pages/TestExecution/Jmeter"), "Jmeter")
);
const JmeterBuild = lazy(() =>
  retryLazy(
    () => import("../pages/TestExecution/Jmeter/JmeterBuild"),
    "JmeterBuild"
  )
);
const Locust = lazy(() =>
  retryLazy(() => import("../pages/TestExecution/Locust"), "Locust")
);

const AcceptanceCriteria = lazy(() =>
  retryLazy(
    () => import("../pages/TestExecution/AcceptanceCriteria"),
    "AcceptanceCriteria"
  )
);

const Reporting = lazy(() =>
  retryLazy(() => import("../pages/Reporting"), "Reporting")
);

const Faqs = lazy(() => retryLazy(() => import("../pages/Faqs"), "Faqs"));
const RouterLayout = ({}) => {
  const [sideMenuCollapse, setSideMenuCollapse] = useState(false);
  const [activeHeader, setActiveHeader] = useState("");
  const [showSideMenu, setShowSideMenu] = useState(true);
  const sideMenuCollapseHandler = () => {
    setSideMenuCollapse(!sideMenuCollapse);
  };
  const history = useHistory();
  const { pathname } = history.location;
  useEffect(() => {
    if (
      pathname.includes("/test-execution") ||
      pathname.includes("/infra-setup")
    ) {
      setShowSideMenu(true);
    } else setShowSideMenu(false);
  }, [activeHeader]);

  // handle browser button back button
  useEffect(() => {
    window.addEventListener("popstate", (popstateEvent) => {
      setActiveHeader(popstateEvent?.currentTarget?.location?.href || "");
    });
    return () => {
      window.removeEventListener("popstate", () => {});
    };
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route
          exact
          path="/login"
          render={(routeProps) => (
            <LoginPage
              {...routeProps}
              activeHeaderHandler={(val) => setActiveHeader(val)}
            />
          )}
        />
      </Switch>
      {/* * //activeHeaderHandler is added to make component rerender*} */}
      <Header activeHeaderHandler={(val) => setActiveHeader(val)} />
      <div className="flex flex-row main-container">
        {showSideMenu && <Sidebar activeStatus={sideMenuCollapse} />}
        <ErrorBoundary>
          <div
            className={`relative overflow-hidden ${
              !showSideMenu
                ? "w-full"
                : !sideMenuCollapse
                ? "main-section"
                : "w-full"
            }`}
          >
            <Switch>
              <Route
                exact
                path="/"
                render={(routeProps) => (
                  <DashBoardPage
                    {...routeProps}
                    activeHeaderHandler={(val) => setActiveHeader(val)}
                  />
                )}
              />
              <Route
                path="/infra-setup"
                exact
                render={(routeProps) => (
                  <TestSetup activeStatus={sideMenuCollapse} {...routeProps} />
                )}
              />
              <Route
                path="/infra-setup/deploy-telegraf"
                exact
                render={(routeProps) => <DeployTelegraf {...routeProps} />}
              />
              <Route
                path="/infra-setup/deploy-prometheus"
                exact
                render={(routeProps) => <DeployPromethus {...routeProps} />}
              />
              <Route
                path="/infra-setup/infra-up"
                exact
                render={(routeProps) => <FrameworkUp {...routeProps} />}
              />
              <Route
                path="/infra-setup/infra-up/build"
                exact
                render={(routeProps) => <FrameworkupBuild {...routeProps} />}
              />
              <Route
                path="/infra-setup/infra-down"
                exact
                render={(routeProps) => <FrameworkDown {...routeProps} />}
              />

              <Route
                path="/infra-setup/infra-down/build"
                exact
                render={(routeProps) => <FrameworkDownBuild {...routeProps} />}
              />
              <Route
                path="/test-execution"
                exact
                render={(routeProps) => (
                  <TestExecution
                    activeStatus={sideMenuCollapse}
                    {...routeProps}
                  />
                )}
              />
              <Route
                path="/test-execution/jmeter"
                exact
                render={(routeProps) => <Jmeter {...routeProps} />}
              />
              <Route
                path="/test-execution/jmeter/build"
                exact
                render={(routeProps) => <JmeterBuild {...routeProps} />}
              />
              <Route
                path="/test-execution/locust"
                exact
                render={(routeProps) => <Locust {...routeProps} />}
              />
              <Route
                path="/test-execution/real-user-monitoring"
                exact
                render={(routeProps) => <RealUserMonitoring {...routeProps} />}
              />
              <Route
                path="/test-execution/real-user-monitoring/build"
                exact
                render={(routeProps) => (
                  <RealUserMonitoringBuild {...routeProps} />
                )}
              />
              <Route
                path="/test-execution/acceptance-criteria"
                exact
                render={(routeProps) => <AcceptanceCriteria {...routeProps} />}
              />
              <Route
                path="/reporting"
                exact
                render={(routeProps) => <Reporting {...routeProps} />}
              />
              <Route
                path="/faqs"
                exact
                render={(routeProps) => <Faqs {...routeProps} />}
              />

              <Route
                path="*"
                exact
                render={(routeProps) => <NotFound {...routeProps} />}
              />
            </Switch>
            {/* sidebar Collapse button */}
            {showSideMenu && (
              <SidebarCollapser
                activeStatus={sideMenuCollapse}
                collapseHandler={sideMenuCollapseHandler}
              />
            )}
          </div>
        </ErrorBoundary>
      </div>
    </Suspense>
  );
};

export default RouterLayout;
