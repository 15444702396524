var _a;
import { createSlice } from "@reduxjs/toolkit";
export var Throughput = createSlice({
    name: "ThroughputReducer",
    initialState: {},
    reducers: {
        storeThroughputData: function (state, action) { return action.payload.data; },
        clearThroughputData: function () {
            return {};
        },
    },
});
export var clearThroughputData = (_a = Throughput.actions, _a.clearThroughputData), storeThroughputData = _a.storeThroughputData;
export default Throughput.reducer;
