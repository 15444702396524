const ChevronLeft = () => {
  return (
    <svg
      width="9"
      height="16"
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.07949 1.76519C1.01183 1.69359 0.958932 1.60936 0.92382 1.51731C0.888708 1.42527 0.87207 1.32721 0.874856 1.22873C0.877641 1.13025 0.899796 1.03329 0.940055 0.943375C0.980315 0.853461 1.03789 0.772356 1.10949 0.704693C1.1811 0.637029 1.26533 0.584131 1.35737 0.54902C1.44942 0.513908 1.54748 0.49727 1.64596 0.500056C1.74443 0.502842 1.8414 0.524996 1.93131 0.565256C2.02122 0.605515 2.10233 0.663089 2.16999 0.734693L8.54499 7.48469C8.67665 7.62394 8.75 7.80831 8.75 7.99994C8.75 8.19158 8.67665 8.37594 8.54499 8.51519L2.16999 15.2659C2.10278 15.3391 2.02169 15.3982 1.93144 15.4398C1.8412 15.4813 1.74359 15.5046 1.6443 15.5081C1.545 15.5116 1.446 15.4954 1.35303 15.4603C1.26006 15.4253 1.17499 15.3721 1.10274 15.3039C1.0305 15.2356 0.972538 15.1538 0.932213 15.0629C0.891889 14.9721 0.870009 14.8742 0.867846 14.7749C0.865683 14.6756 0.883278 14.5768 0.919611 14.4843C0.955944 14.3918 1.01029 14.3075 1.07949 14.2362L6.96849 7.99994L1.07949 1.76519V1.76519Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChevronLeft;
export { ChevronLeft };
