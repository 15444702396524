import React, { useState, useRef, useEffect } from "react";
var ProgressBar = function (_a) {
    var estimateData = _a.estimateData, jobName = _a.jobName;
    var _b = useState(function () {
        var remainingDuration = new Date().getTime() - new Date(estimateData.started_at).getTime();
        var estDur = estimateData.estimatedDuration && estimateData.estimatedDuration !== -1
            ? estimateData.estimatedDuration
            : mapJobNameEstimateDuration[jobName];
        var duration = Math.trunc((remainingDuration / estDur) * 100);
        return duration >= 98 ? 98 : duration;
    }), timer = _b[0], setTimer = _b[1];
    var interval = useRef(null);
    useEffect(function () {
        var estimateDuration = estimateData.estimatedDuration && estimateData.estimatedDuration !== -1
            ? estimateData.estimatedDuration
            : mapJobNameEstimateDuration[jobName];
        interval = setInterval(function () {
            setTimer(function (prev) {
                if (prev >= 99) {
                    clearInterval(interval);
                    return;
                }
                return prev + 1;
            });
        }, estimateDuration / 100);
        return function () { return clearInterval(interval); };
    }, []);
    return (React.createElement("div", { className: "rounded w-full bg-neutrals-EAE h-6 shadow-[0_0_1px_#13294B]" },
        React.createElement("div", { className: "bg-blue-132 rounded transition-[width] duration-[2000ms]", style: { width: "".concat(timer, "%") } },
            React.createElement("span", { className: "text-blue-132 invisible" }, "s"))));
};
var mapJobNameEstimateDuration = {
    PHOTON_Test_Execution: 10 * 60 * 1000,
    PHOTON_Framework_Down: 15 * 60 * 1000,
    PHOTON_Framework_Up: 30 * 60 * 1000,
    PHOTON_Real_User_Monitoring: 5 * 60 * 1000,
};
export default ProgressBar;
