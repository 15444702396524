import React, { useRef, useState } from "react";
import { Snackbar } from "../components/common";
export var GlobalContextAPI = React.createContext({
    snackbar: {
        item: {
            message: React.createElement(React.Fragment, null),
            type: "success",
            value: false,
        },
        handler: function (item) { },
    },
});
export var GlobalContext = function (_a) {
    var children = _a.children;
    var _b = useState({
        message: React.createElement(React.Fragment, null),
        value: false,
        type: "success",
        timeoutTimer: 4000,
    }), snackbarItem = _b[0], setSnackbarItem = _b[1];
    var timer = useRef(null);
    var snackBarHandle = function (item) {
        setSnackbarItem(item);
        timer.current = setTimeout(function () {
            snackBarCloseHandler();
        }, item.timeoutTimer || 4000);
        return;
    };
    var snackBarCloseHandler = function () {
        clearTimeout(timer === null || timer === void 0 ? void 0 : timer.current);
        return setSnackbarItem({
            message: React.createElement(React.Fragment, null),
            type: "success",
            value: false,
        });
    };
    var initialContextData = {
        snackbar: {
            item: snackbarItem,
            handler: snackBarHandle,
        },
    };
    return (React.createElement(GlobalContextAPI.Provider, { value: initialContextData },
        children,
        snackbarItem.value && React.createElement(Snackbar, { onClose: snackBarCloseHandler })));
};
