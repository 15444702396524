var _a;
import { createSlice } from "@reduxjs/toolkit";
export var ErrorPerMinutes = createSlice({
    name: "ErrorPerMinutesReducer",
    initialState: {},
    reducers: {
        storeErrorPerMinutesData: function (state, action) {
            return action.payload.data;
        },
        clearErrorPerMinutesData: function () {
            return {};
        },
    },
});
export var clearErrorPerMinutesData = (_a = ErrorPerMinutes.actions, _a.clearErrorPerMinutesData), storeErrorPerMinutesData = _a.storeErrorPerMinutesData;
export default ErrorPerMinutes.reducer;
