import { createSlice } from "@reduxjs/toolkit";
export var FrameworkUpSlice = createSlice({
    name: "FrameworkUpStatus",
    initialState: {},
    reducers: {
        storeFrameworkBuildDetails: function (state, action) { return (state = action.payload); },
    },
});
export var storeFrameworkBuildDetails = FrameworkUpSlice.actions.storeFrameworkBuildDetails;
export default FrameworkUpSlice.reducer;
