import React from "react";
export var CrossX = function (_a) {
    var _b = _a.classname, classname = _b === void 0 ? "w-[34px] h=[34px]" : _b;
    return (React.createElement("svg", { className: classname, viewBox: "0 0 34 34", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_384_339)" },
            React.createElement("path", { d: "M24.0415 9.89919C27.9469 13.8045 27.9469 20.136 24.0415 24.0413C20.1362 27.9467 13.8047 27.9467 9.89939 24.0413C5.99404 20.136 5.99404 13.8045 9.89939 9.89919C13.8047 5.99384 20.1362 5.99384 24.0415 9.89919ZM20.506 13.4347C20.3778 13.3066 20.2076 13.2292 20.0268 13.2168C19.846 13.2045 19.6668 13.258 19.5224 13.3676L19.4453 13.4347L16.9705 15.9096L14.4956 13.4347C14.3612 13.3004 14.1808 13.2221 13.9909 13.2157C13.801 13.2092 13.6157 13.2751 13.4725 13.4C13.3293 13.5249 13.2388 13.6995 13.2194 13.8885C13.1999 14.0775 13.253 14.2669 13.3678 14.4183L13.4349 14.4954L15.9098 16.9703L13.4349 19.4451C13.3006 19.5795 13.2223 19.7599 13.2159 19.9498C13.2094 20.1398 13.2753 20.325 13.4002 20.4683C13.5251 20.6115 13.6997 20.7019 13.8887 20.7214C14.0777 20.7408 14.2671 20.6878 14.4185 20.573L14.4956 20.5058L16.9705 18.0309L19.4453 20.5058C19.5797 20.6401 19.7601 20.7184 19.95 20.7249C20.14 20.7313 20.3252 20.6654 20.4685 20.5405C20.6117 20.4157 20.7021 20.241 20.7216 20.052C20.741 19.863 20.688 19.6736 20.5732 19.5222L20.506 19.4451L18.0311 16.9703L20.506 14.4954C20.6466 14.3547 20.7257 14.164 20.7257 13.9651C20.7257 13.7661 20.6466 13.5754 20.506 13.4347Z", fill: "currentColor" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_384_339" },
                React.createElement("rect", { width: "24", height: "24", fill: "white", transform: "translate(16.9705) rotate(45)" })))));
};
