export const Page404Icon = () => {
  return (
    <svg
      width="500"
      height="400"
      viewBox="0 0 570 570"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M271.776 507.357H51.642C49.9156 507.357 48.2599 506.671 47.0391 505.45C45.8184 504.23 45.1326 502.574 45.1326 500.847V69.1524C45.1476 67.4358 45.84 65.7948 47.0592 64.5863C48.2783 63.3779 49.9254 62.6999 51.642 62.7H271.776C272.631 62.7 273.477 62.8683 274.267 63.1954C275.057 63.5226 275.774 64.0021 276.379 64.6065C276.983 65.211 277.463 65.9286 277.79 66.7183C278.117 67.5081 278.285 68.3545 278.285 69.2093V500.847C278.285 501.702 278.117 502.549 277.79 503.339C277.463 504.128 276.983 504.846 276.379 505.45C275.774 506.055 275.057 506.534 274.267 506.861C273.477 507.189 272.631 507.357 271.776 507.357ZM51.642 62.928C49.9921 62.931 48.4106 63.5877 47.244 64.7544C46.0773 65.921 45.4206 67.5025 45.4176 69.1524V500.847C45.4206 502.497 46.0773 504.079 47.244 505.245C48.4106 506.412 49.9921 507.069 51.642 507.072H271.776C273.426 507.069 275.007 506.412 276.174 505.245C277.341 504.079 277.997 502.497 278 500.847V69.1524C277.997 67.5025 277.341 65.921 276.174 64.7544C275.007 63.5877 273.426 62.931 271.776 62.928H51.642Z"
        fill="#EBEBEB"
      />
      <path
        d="M518.358 507.357H298.224C296.498 507.357 294.842 506.671 293.621 505.45C292.4 504.23 291.715 502.574 291.715 500.847V69.1524C291.73 67.4358 292.422 65.7948 293.641 64.5863C294.86 63.3779 296.507 62.6999 298.224 62.7H518.358C520.084 62.7 521.74 63.3858 522.961 64.6065C524.182 65.8273 524.867 67.4829 524.867 69.2093V500.847C524.867 501.702 524.699 502.549 524.372 503.339C524.045 504.128 523.565 504.846 522.961 505.45C522.356 506.055 521.639 506.534 520.849 506.861C520.059 507.189 519.213 507.357 518.358 507.357ZM298.224 62.928C296.574 62.931 294.993 63.5877 293.826 64.7544C292.659 65.921 292.003 67.5025 292 69.1524V500.847C292.003 502.497 292.659 504.079 293.826 505.245C294.993 506.412 296.574 507.069 298.224 507.072H518.358C520.008 507.069 521.589 506.412 522.756 505.245C523.923 504.079 524.579 502.497 524.582 500.847V69.1524C524.579 67.5025 523.923 65.921 522.756 64.7544C521.589 63.5877 520.008 62.931 518.358 62.928H298.224Z"
        fill="#EBEBEB"
      />
      <path
        d="M128.877 191.805L130.724 195.533L134.851 196.137L131.864 199.044L132.571 203.148L128.877 201.21L125.195 203.148L125.902 199.044L122.915 196.137L127.042 195.533L128.877 191.805Z"
        fill="#EBEBEB"
      />
      <path
        d="M497.838 367.627L499.685 371.366L503.812 371.971L500.825 374.866L501.532 378.982L497.838 377.044L494.156 378.982L494.863 374.866L491.876 371.971L496.003 371.366L497.838 367.627Z"
        fill="#F5F5F5"
      />
      <path
        d="M424.764 476.03L426.611 479.758L430.726 480.362L427.739 483.269L428.446 487.373L424.764 485.435L421.082 487.373L421.777 483.269L418.802 480.362L422.917 479.758L424.764 476.03Z"
        fill="#F5F5F5"
      />
      <path
        d="M257.572 125.571L258.255 126.962L259.795 127.19C259.88 127.201 259.961 127.237 260.028 127.292C260.094 127.348 260.143 127.421 260.17 127.503C260.196 127.586 260.198 127.674 260.176 127.758C260.154 127.842 260.109 127.918 260.045 127.976L258.905 129.059L259.168 130.587C259.181 130.672 259.171 130.759 259.138 130.839C259.105 130.918 259.051 130.987 258.981 131.038C258.912 131.089 258.829 131.119 258.744 131.126C258.658 131.133 258.572 131.116 258.495 131.077L257.127 130.348L255.759 131.077C255.682 131.116 255.596 131.133 255.51 131.126C255.424 131.119 255.342 131.089 255.273 131.038C255.203 130.987 255.149 130.918 255.116 130.839C255.083 130.759 255.073 130.672 255.086 130.587L255.337 129.059L254.197 127.976C254.133 127.917 254.088 127.84 254.066 127.755C254.045 127.671 254.048 127.582 254.076 127.499C254.103 127.416 254.154 127.343 254.222 127.288C254.29 127.233 254.372 127.199 254.459 127.19L255.987 126.962L256.671 125.571C256.698 125.473 256.757 125.386 256.838 125.324C256.92 125.262 257.019 125.229 257.121 125.229C257.223 125.229 257.323 125.262 257.404 125.324C257.485 125.386 257.544 125.473 257.572 125.571Z"
        fill="#EBEBEB"
      />
      <path
        d="M69.2891 458.28L69.9731 459.659L71.5007 459.887C71.5875 459.899 71.6691 459.935 71.7363 459.991C71.8036 460.048 71.8537 460.121 71.881 460.205C71.9083 460.288 71.9118 460.377 71.8909 460.462C71.87 460.547 71.8257 460.624 71.7629 460.685L70.6229 461.757L70.8851 463.285C70.8986 463.37 70.8883 463.457 70.8555 463.536C70.8226 463.616 70.7684 463.685 70.6988 463.735C70.6292 463.786 70.547 463.817 70.4612 463.824C70.3754 463.831 70.2894 463.814 70.2125 463.775L68.8445 463.057L67.4765 463.775C67.3997 463.814 67.3137 463.831 67.2279 463.824C67.1421 463.817 67.0599 463.786 66.9903 463.735C66.9207 463.685 66.8665 463.616 66.8336 463.536C66.8008 463.457 66.7905 463.37 66.8039 463.285L67.0662 461.757L65.9262 460.685C65.8636 460.625 65.8191 460.549 65.7977 460.464C65.7762 460.38 65.7787 460.292 65.8047 460.209C65.8308 460.126 65.8794 460.052 65.9451 459.995C66.0109 459.938 66.0912 459.901 66.1769 459.887L67.7045 459.659L68.3999 458.28C68.4304 458.186 68.4898 458.104 68.5697 458.046C68.6496 457.988 68.7458 457.957 68.8445 457.957C68.9433 457.957 69.0395 457.988 69.1194 458.046C69.1993 458.104 69.2587 458.186 69.2891 458.28Z"
        fill="#F5F5F5"
      />
      <path
        d="M100.035 110.922L100.719 112.313L102.258 112.529C102.344 112.543 102.424 112.58 102.49 112.637C102.556 112.694 102.604 112.768 102.63 112.851C102.656 112.934 102.659 113.022 102.637 113.106C102.616 113.191 102.571 113.267 102.509 113.327L101.369 114.467L101.631 115.984C101.649 116.07 101.642 116.159 101.611 116.241C101.579 116.323 101.525 116.395 101.453 116.447C101.382 116.498 101.298 116.528 101.21 116.533C101.122 116.538 101.035 116.517 100.958 116.474L99.5904 115.756L98.2224 116.474C98.146 116.517 98.0587 116.538 97.9708 116.533C97.883 116.528 97.7984 116.498 97.7273 116.447C97.6562 116.395 97.6016 116.323 97.5701 116.241C97.5386 116.159 97.5316 116.07 97.5498 115.984L97.8006 114.467L96.6606 113.327C96.5979 113.266 96.5535 113.189 96.5326 113.104C96.5118 113.019 96.5152 112.93 96.5425 112.847C96.5698 112.763 96.62 112.69 96.6872 112.633C96.7544 112.577 96.8361 112.541 96.9228 112.529L98.4504 112.313L99.18 110.922C99.2166 110.839 99.2765 110.769 99.3524 110.719C99.4283 110.67 99.5169 110.644 99.6075 110.644C99.6981 110.644 99.7867 110.67 99.8626 110.719C99.9385 110.769 99.9984 110.839 100.035 110.922Z"
        fill="#EBEBEB"
      />
      <path
        d="M98.1881 385.685C98.1904 385.985 98.1034 386.279 97.9381 386.53C97.7729 386.781 97.5369 386.977 97.2601 387.094C96.9832 387.21 96.678 387.242 96.3832 387.185C96.0883 387.127 95.8171 386.984 95.6039 386.772C95.3907 386.561 95.2452 386.291 95.1857 385.996C95.1263 385.702 95.1557 385.396 95.2701 385.119C95.3845 384.841 95.5788 384.603 95.8284 384.436C96.078 384.269 96.3716 384.18 96.6719 384.18C97.0721 384.18 97.456 384.338 97.74 384.62C98.024 384.902 98.1851 385.285 98.1881 385.685Z"
        fill="#EBEBEB"
      />
      <path
        d="M314.264 195.955C314.266 196.255 314.179 196.549 314.014 196.8C313.849 197.051 313.613 197.247 313.336 197.363C313.059 197.48 312.754 197.512 312.459 197.454C312.164 197.397 311.893 197.254 311.68 197.042C311.466 196.831 311.321 196.56 311.261 196.266C311.202 195.972 311.231 195.666 311.346 195.388C311.46 195.111 311.654 194.873 311.904 194.706C312.154 194.539 312.447 194.45 312.748 194.45C312.946 194.448 313.143 194.486 313.327 194.561C313.511 194.636 313.678 194.747 313.819 194.886C313.96 195.026 314.072 195.193 314.148 195.376C314.224 195.559 314.264 195.756 314.264 195.955Z"
        fill="#EBEBEB"
      />
      <path
        d="M235.649 112.176C235.649 112.476 235.56 112.769 235.394 113.018C235.227 113.268 234.99 113.462 234.713 113.577C234.436 113.692 234.131 113.722 233.837 113.663C233.543 113.605 233.273 113.46 233.061 113.248C232.849 113.036 232.705 112.766 232.646 112.472C232.588 112.178 232.618 111.873 232.732 111.596C232.847 111.319 233.041 111.082 233.291 110.915C233.54 110.749 233.833 110.66 234.133 110.66C234.534 110.663 234.918 110.823 235.202 111.107C235.486 111.391 235.646 111.775 235.649 112.176Z"
        fill="#F5F5F5"
      />
      <path
        d="M238.05 481.7C242.554 480.637 245.344 476.124 244.281 471.62C243.218 467.116 238.705 464.327 234.201 465.39C229.697 466.453 226.908 470.966 227.971 475.47C229.034 479.973 233.547 482.763 238.05 481.7Z"
        fill="#F0F0F0"
      />
      <path
        d="M233.244 468.643C231.528 468.639 229.855 469.182 228.467 470.193C227.759 471.818 227.585 473.627 227.971 475.357C228.356 477.088 229.282 478.651 230.613 479.821C231.945 480.992 233.614 481.709 235.38 481.87C237.145 482.031 238.916 481.626 240.437 480.715C241.111 479.47 241.449 478.071 241.419 476.656C241.389 475.24 240.992 473.857 240.267 472.641C239.542 471.425 238.514 470.418 237.282 469.72C236.051 469.021 234.66 468.653 233.244 468.654V468.643Z"
        fill="#E6E6E6"
      />
      <path
        d="M168.355 327.91H98.9862V296.594L168.355 214.126H201.552V298.372H218.755V327.91H201.552V353.537H168.355V327.91ZM168.355 298.372V255.235L131.693 298.372H168.355Z"
        fill="#13294B"
      />
      <path
        d="M230.622 284.441C230.622 258.381 235.311 240.141 244.69 229.721C254.068 219.302 268.36 214.096 287.565 214.103C296.784 214.103 304.357 215.243 310.285 217.523C315.67 219.464 320.601 222.486 324.775 226.404C328.292 229.762 331.272 233.642 333.61 237.907C335.923 242.359 337.666 247.086 338.797 251.974C341.374 262.418 342.66 273.138 342.627 283.894C342.627 308.761 338.42 326.96 330.007 338.489C321.594 350.018 307.105 355.786 286.539 355.794C275.002 355.794 265.681 353.955 258.575 350.276C251.412 346.538 245.378 340.953 241.099 334.1C237.793 329.053 235.22 322.149 233.381 313.386C231.471 303.858 230.547 294.159 230.622 284.441ZM268.402 284.533C268.402 302.013 269.941 313.933 273.019 320.294C276.097 326.656 280.569 329.851 286.436 329.882C288.298 329.903 290.146 329.554 291.872 328.855C293.598 328.156 295.168 327.122 296.491 325.812C299.334 323.099 301.424 318.812 302.761 312.953C304.099 307.093 304.768 297.973 304.768 285.593C304.768 267.398 303.225 255.166 300.139 248.896C297.054 242.626 292.425 239.495 286.254 239.503C279.961 239.503 275.401 242.698 272.574 249.09C269.747 255.482 268.356 267.296 268.402 284.533Z"
        fill="#13294B"
      />
      <path
        d="M423.784 327.91H354.415V296.594L423.784 214.126H456.98V298.372H474.24V327.91H456.98V353.537H423.784V327.91ZM423.784 298.372V255.235L387.133 298.372H423.784Z"
        fill="#13294B"
      />
      <g opacity="0.3">
        <path
          d="M229.14 166.007C229.14 166.429 229.015 166.841 228.78 167.192C228.545 167.543 228.212 167.816 227.821 167.977C227.431 168.138 227.002 168.18 226.588 168.097C226.174 168.013 225.794 167.809 225.497 167.51C225.199 167.211 224.997 166.83 224.916 166.416C224.835 166.001 224.879 165.572 225.042 165.183C225.205 164.794 225.48 164.461 225.832 164.229C226.184 163.996 226.598 163.873 227.02 163.875C227.299 163.875 227.576 163.93 227.834 164.037C228.092 164.145 228.326 164.302 228.523 164.5C228.72 164.698 228.876 164.933 228.982 165.192C229.088 165.45 229.142 165.727 229.14 166.007Z"
          fill="#13294B"
        />
        <path
          d="M83.1858 247.893C84.0169 247.893 84.6906 247.219 84.6906 246.388C84.6906 245.557 84.0169 244.883 83.1858 244.883C82.3548 244.883 81.681 245.557 81.681 246.388C81.681 247.219 82.3548 247.893 83.1858 247.893Z"
          fill="#13294B"
        />
        <path
          d="M331.797 467.651C332.987 467.651 333.952 466.686 333.952 465.496C333.952 464.306 332.987 463.342 331.797 463.342C330.607 463.342 329.643 464.306 329.643 465.496C329.643 466.686 330.607 467.651 331.797 467.651Z"
          fill="#13294B"
        />
        <path
          d="M383.61 379.985C384.441 379.985 385.115 379.311 385.115 378.48C385.115 377.649 384.441 376.975 383.61 376.975C382.779 376.975 382.105 377.649 382.105 378.48C382.105 379.311 382.779 379.985 383.61 379.985Z"
          fill="#13294B"
        />
        <path
          d="M483.554 109.007C483.554 109.304 483.466 109.595 483.3 109.843C483.135 110.09 482.9 110.283 482.625 110.397C482.35 110.511 482.047 110.541 481.755 110.483C481.464 110.425 481.195 110.281 480.985 110.071C480.774 109.86 480.631 109.592 480.573 109.3C480.515 109.008 480.545 108.706 480.659 108.431C480.773 108.156 480.965 107.921 481.213 107.756C481.46 107.59 481.751 107.502 482.049 107.502C482.448 107.502 482.831 107.66 483.113 107.943C483.395 108.225 483.554 108.608 483.554 109.007Z"
          fill="#13294B"
        />
        <path
          d="M196.935 78.66C196.935 78.9576 196.847 79.2485 196.681 79.496C196.516 79.7434 196.281 79.9363 196.006 80.0502C195.731 80.1641 195.429 80.1939 195.137 80.1358C194.845 80.0778 194.577 79.9345 194.366 79.724C194.156 79.5136 194.012 79.2454 193.954 78.9535C193.896 78.6616 193.926 78.3591 194.04 78.0841C194.154 77.8091 194.347 77.5741 194.594 77.4088C194.842 77.2434 195.133 77.1552 195.43 77.1552C195.828 77.1581 196.209 77.3176 196.491 77.5992C196.773 77.8808 196.932 78.2618 196.935 78.66Z"
          fill="#13294B"
        />
        <path
          d="M316.578 157.616C317.409 157.616 318.083 156.943 318.083 156.112C318.083 155.281 317.409 154.607 316.578 154.607C315.747 154.607 315.073 155.281 315.073 156.112C315.073 156.943 315.747 157.616 316.578 157.616Z"
          fill="#13294B"
        />
      </g>
      <path
        d="M161.002 157.628C174.797 157.628 185.98 146.445 185.98 132.65C185.98 118.856 174.797 107.673 161.002 107.673C147.208 107.673 136.025 118.856 136.025 132.65C136.025 146.445 147.208 157.628 161.002 157.628Z"
        fill="#13294B"
      />
      <path
        opacity="0.7"
        d="M161.002 157.628C174.797 157.628 185.98 146.445 185.98 132.65C185.98 118.856 174.797 107.673 161.002 107.673C147.208 107.673 136.025 118.856 136.025 132.65C136.025 146.445 147.208 157.628 161.002 157.628Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M152.395 113.806C148.994 113.796 145.626 114.482 142.5 115.824C138.658 120.046 136.378 125.457 136.039 131.155C135.699 136.854 137.321 142.497 140.635 147.146C143.948 151.794 148.754 155.168 154.251 156.706C159.749 158.244 165.607 157.853 170.852 155.599C174.103 152.015 176.245 147.565 177.016 142.788C177.787 138.011 177.154 133.113 175.195 128.688C173.235 124.264 170.034 120.503 165.978 117.864C161.923 115.224 157.188 113.818 152.35 113.818L152.395 113.806Z"
        fill="#13294B"
      />
      <path
        opacity="0.2"
        d="M149.91 120.407C149.91 120.858 149.776 121.298 149.526 121.673C149.275 122.048 148.919 122.341 148.502 122.513C148.086 122.686 147.627 122.731 147.185 122.643C146.743 122.555 146.337 122.338 146.018 122.019C145.699 121.7 145.482 121.294 145.394 120.852C145.306 120.409 145.351 119.951 145.524 119.534C145.696 119.118 145.988 118.761 146.363 118.511C146.738 118.26 147.179 118.127 147.63 118.127C148.235 118.127 148.815 118.367 149.242 118.795C149.67 119.222 149.91 119.802 149.91 120.407Z"
        fill="#13294B"
      />
      <path
        opacity="0.2"
        d="M176.768 118.127C176.768 118.578 176.635 119.019 176.384 119.393C176.134 119.768 175.778 120.061 175.361 120.233C174.944 120.406 174.486 120.451 174.044 120.363C173.601 120.275 173.195 120.058 172.876 119.739C172.557 119.42 172.34 119.014 172.252 118.572C172.164 118.129 172.209 117.671 172.382 117.254C172.555 116.838 172.847 116.482 173.222 116.231C173.597 115.981 174.038 115.847 174.488 115.847C175.093 115.847 175.673 116.087 176.101 116.515C176.528 116.942 176.768 117.522 176.768 118.127Z"
        fill="#13294B"
      />
      <path
        opacity="0.2"
        d="M172.208 134.406C172.208 135.145 171.989 135.868 171.578 136.483C171.167 137.098 170.583 137.578 169.9 137.861C169.217 138.144 168.465 138.218 167.74 138.073C167.014 137.929 166.348 137.573 165.825 137.05C165.302 136.527 164.946 135.861 164.802 135.135C164.658 134.41 164.732 133.658 165.015 132.975C165.298 132.292 165.777 131.708 166.392 131.297C167.007 130.886 167.73 130.667 168.469 130.667C169.461 130.667 170.412 131.061 171.113 131.762C171.814 132.463 172.208 133.414 172.208 134.406Z"
        fill="#13294B"
      />
      <path
        opacity="0.2"
        d="M160.33 145.065C160.33 146.053 160.037 147.018 159.488 147.839C158.94 148.66 158.16 149.3 157.247 149.678C156.335 150.056 155.331 150.155 154.362 149.962C153.394 149.77 152.504 149.294 151.806 148.596C151.107 147.897 150.632 147.008 150.439 146.039C150.247 145.071 150.345 144.067 150.723 143.154C151.101 142.242 151.741 141.462 152.562 140.913C153.384 140.365 154.349 140.072 155.336 140.072C156.661 140.072 157.931 140.598 158.867 141.534C159.804 142.471 160.33 143.741 160.33 145.065Z"
        fill="#13294B"
      />
      <path
        d="M457.026 433.936C459.807 422.153 452.509 410.346 440.725 407.565C428.942 404.784 417.135 412.081 414.354 423.865C411.572 435.648 418.87 447.456 430.654 450.237C442.437 453.018 454.244 445.72 457.026 433.936Z"
        fill="#13294B"
      />
      <path
        opacity="0.3"
        d="M457.026 433.936C459.807 422.153 452.509 410.346 440.725 407.565C428.942 404.784 417.135 412.081 414.354 423.865C411.572 435.648 418.87 447.456 430.654 450.237C442.437 453.018 454.244 445.72 457.026 433.936Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M449.536 411.928C444.677 410.461 439.458 410.727 434.773 412.68C430.088 414.634 426.226 418.154 423.848 422.638C421.47 427.123 420.722 432.294 421.734 437.269C422.745 442.243 425.452 446.712 429.393 449.912C431.436 450.533 433.561 450.848 435.697 450.847C440.223 450.846 444.637 449.444 448.334 446.834C452.032 444.224 454.831 440.534 456.347 436.27C457.863 432.006 458.023 427.377 456.803 423.018C455.584 418.66 453.045 414.786 449.536 411.928Z"
        fill="#13294B"
      />
      <path
        d="M414.766 435.48C391.362 446.492 408.815 454.985 441.796 443.129C472.336 432.151 483.976 418.311 456.616 422.609C458.086 428.366 419.839 443.266 414.766 435.48Z"
        fill="#13294B"
      />
      <path
        opacity="0.2"
        d="M449.274 214.126C418.619 234.84 378.936 262.2 367.981 327.91H365.644C376.303 262.93 414.538 234.92 445.204 214.126H449.274Z"
        fill="black"
      />
      <path
        d="M290.7 419.828C271.32 419.828 252.157 411.084 242.82 396.811C237.063 387.988 230.371 369.508 250.298 344.85L252.065 346.286C237.975 363.728 235.376 381.227 244.735 395.569C256.135 413.011 283.028 421.789 305.987 415.541C329.506 409.146 343.778 388.694 345.158 359.431C349.866 259.327 403.047 228.49 441.875 205.975C466.009 191.987 483.44 181.887 479.609 160.558C479.074 157.605 477.899 155.564 475.996 154.31C471.037 151.039 461.347 153.307 450.129 155.918C428.116 161.048 397.974 168.07 380.019 137.53L381.991 136.39C399.091 165.494 427.101 158.962 449.616 153.718C461.347 150.993 471.47 148.633 477.25 152.429C479.701 154.037 481.205 156.579 481.81 160.181C485.925 183.073 466.99 194.062 442.993 207.97C404.609 230.223 352.055 260.695 347.404 359.59C345.967 389.914 331.079 411.13 306.546 417.799C301.377 419.175 296.049 419.857 290.7 419.828Z"
        fill="#13294B"
      />
      <path
        opacity="0.2"
        d="M290.7 419.828C271.32 419.828 252.157 411.084 242.82 396.811C237.063 387.988 230.371 369.508 250.298 344.85L252.065 346.286C237.975 363.728 235.376 381.227 244.735 395.569C256.135 413.011 283.028 421.789 305.987 415.541C329.506 409.146 343.778 388.694 345.158 359.431C349.866 259.327 403.047 228.49 441.875 205.975C466.009 191.987 483.44 181.887 479.609 160.558C479.074 157.605 477.899 155.564 475.996 154.31C471.037 151.039 461.347 153.307 450.129 155.918C428.116 161.048 397.974 168.07 380.019 137.53L381.991 136.39C399.091 165.494 427.101 158.962 449.616 153.718C461.347 150.993 471.47 148.633 477.25 152.429C479.701 154.037 481.205 156.579 481.81 160.181C485.925 183.073 466.99 194.062 442.993 207.97C404.609 230.223 352.055 260.695 347.404 359.59C345.967 389.914 331.079 411.13 306.546 417.799C301.377 419.175 296.049 419.857 290.7 419.828Z"
        fill="white"
      />
      <path
        d="M356.546 110.58C361.799 110.61 367.018 111.429 372.028 113.008C372.028 113.008 384.568 134.406 386.05 139.308C385.525 144.142 377.374 152.065 377.374 152.065L356.546 110.58Z"
        fill="#13294B"
      />
      <path
        opacity="0.3"
        d="M356.546 110.58C361.799 110.61 367.018 111.429 372.028 113.008C372.028 113.008 384.568 134.406 386.05 139.308C385.525 144.142 377.374 152.065 377.374 152.065L356.546 110.58Z"
        fill="white"
      />
      <path
        d="M393.688 214.468C388.859 208.563 384.455 202.323 380.509 195.795C379.552 194.146 378.643 192.466 377.785 190.756C377.294 189.787 376.827 188.818 376.371 187.838C375.773 186.884 375.309 185.852 374.992 184.771C373.624 170.327 378.571 159.691 373.852 148.097L355.064 155.439C355.064 155.439 356.66 176.096 360.308 188.499C362.588 196.171 367.696 202.806 372.632 208.951C374.171 210.866 375.653 212.838 377.192 214.765C378.731 216.691 380.281 218.253 381.752 220.066C383.975 222.813 384.704 225.446 383.029 228.684L382.744 229.197C382.265 230.041 384.499 230.998 385.913 229.197C388.193 226.256 387.874 226.438 389.857 223.93C391.066 222.414 392.445 220.761 393.642 219.37C394.219 218.686 394.54 217.822 394.548 216.927C394.557 216.032 394.252 215.163 393.688 214.468Z"
        fill="#13294B"
      />
      <path
        opacity="0.7"
        d="M393.688 214.468C388.859 208.563 384.455 202.323 380.509 195.795C379.552 194.146 378.643 192.466 377.785 190.756C377.294 189.787 376.827 188.818 376.371 187.838C375.773 186.884 375.309 185.852 374.992 184.771C373.624 170.327 378.571 159.691 373.852 148.097L355.064 155.439C355.064 155.439 356.66 176.096 360.308 188.499C362.588 196.171 367.696 202.806 372.632 208.951C374.171 210.866 375.653 212.838 377.192 214.765C378.731 216.691 380.281 218.253 381.752 220.066C383.975 222.813 384.704 225.446 383.029 228.684L382.744 229.197C382.265 230.041 384.499 230.998 385.913 229.197C388.193 226.256 387.874 226.438 389.857 223.93C391.066 222.414 392.445 220.761 393.642 219.37C394.219 218.686 394.54 217.822 394.548 216.927C394.557 216.032 394.252 215.163 393.688 214.468Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M389.093 208.529C386.112 211.725 382.803 214.598 379.221 217.102C379.711 217.649 380.19 218.242 380.68 218.766C384.267 216.343 387.524 213.465 390.37 210.205L389.093 208.529Z"
        fill="#13294B"
      />
      <path
        opacity="0.3"
        d="M393.688 214.468L393.551 214.309C392.674 215.315 392.163 216.589 392.103 217.922C392.03 218.837 392.178 219.756 392.536 220.601L393.619 219.313C394.185 218.636 394.501 217.785 394.514 216.903C394.526 216.021 394.234 215.161 393.688 214.468Z"
        fill="#13294B"
      />
      <path
        d="M352.077 124.26C350.041 127.31 347.598 130.067 344.816 132.457C343.273 133.786 341.582 134.934 339.777 135.877C338.828 136.363 337.845 136.778 336.836 137.119L336.06 137.37L335.81 137.438L335.274 137.587C334.944 137.665 334.609 137.718 334.271 137.746C333.309 137.82 332.342 137.731 331.409 137.484C330.233 137.15 329.107 136.663 328.058 136.036C327.214 135.543 326.396 135.006 325.607 134.429C324.181 133.369 322.819 132.227 321.525 131.009C319.032 128.646 316.744 126.076 314.685 123.325C314.289 122.789 314.092 122.132 314.129 121.467C314.165 120.801 314.433 120.169 314.886 119.68C315.338 119.191 315.948 118.875 316.608 118.787C317.269 118.699 317.94 118.845 318.504 119.198H318.596C321.286 120.908 323.999 122.71 326.644 124.317C327.989 125.104 329.289 125.902 330.577 126.517C331.165 126.825 331.77 127.099 332.39 127.338C332.775 127.508 333.188 127.609 333.609 127.634C333.678 127.634 333.609 127.555 333.188 127.566C333.055 127.558 332.922 127.558 332.789 127.566L332.538 127.623L332.914 127.429C333.421 127.157 333.912 126.856 334.385 126.529C335.405 125.809 336.358 125.001 337.235 124.112C339.226 122.025 341.092 119.821 342.821 117.511C343.768 116.451 345.077 115.784 346.491 115.639C347.906 115.495 349.323 115.884 350.465 116.731C351.607 117.577 352.391 118.82 352.664 120.215C352.937 121.61 352.679 123.057 351.941 124.271L352.077 124.26Z"
        fill="#13294B"
      />
      <path
        opacity="0.7"
        d="M352.077 124.26C350.041 127.31 347.598 130.067 344.816 132.457C343.273 133.786 341.582 134.934 339.777 135.877C338.828 136.363 337.845 136.778 336.836 137.119L336.06 137.37L335.81 137.438L335.274 137.587C334.944 137.665 334.609 137.718 334.271 137.746C333.309 137.82 332.342 137.731 331.409 137.484C330.233 137.15 329.107 136.663 328.058 136.036C327.214 135.543 326.396 135.006 325.607 134.429C324.181 133.369 322.819 132.227 321.525 131.009C319.032 128.646 316.744 126.076 314.685 123.325C314.289 122.789 314.092 122.132 314.129 121.467C314.165 120.801 314.433 120.169 314.886 119.68C315.338 119.191 315.948 118.875 316.608 118.787C317.269 118.699 317.94 118.845 318.504 119.198H318.596C321.286 120.908 323.999 122.71 326.644 124.317C327.989 125.104 329.289 125.902 330.577 126.517C331.165 126.825 331.77 127.099 332.39 127.338C332.775 127.508 333.188 127.609 333.609 127.634C333.678 127.634 333.609 127.555 333.188 127.566C333.055 127.558 332.922 127.558 332.789 127.566L332.538 127.623L332.914 127.429C333.421 127.157 333.912 126.856 334.385 126.529C335.405 125.809 336.358 125.001 337.235 124.112C339.226 122.025 341.092 119.821 342.821 117.511C343.768 116.451 345.077 115.784 346.491 115.639C347.906 115.495 349.323 115.884 350.465 116.731C351.607 117.577 352.391 118.82 352.664 120.215C352.937 121.61 352.679 123.057 351.941 124.271L352.077 124.26Z"
        fill="white"
      />
      <path
        d="M310.411 116.759L311.744 119.039C311.744 119.039 312.759 122.026 314.8 122.573L320.34 120.783L320.055 120.316C319.348 119.244 319.428 117.158 319.667 115.425C319.907 113.692 319.018 113.635 318.356 114.068C317.888 114.607 317.559 115.253 317.399 115.949C317.127 115.573 316.826 115.218 316.498 114.889L314.811 113.202C314.457 112.856 313.985 112.657 313.491 112.644C312.996 112.631 312.515 112.806 312.143 113.134L310.775 114.353C310.451 114.653 310.238 115.055 310.172 115.492C310.105 115.929 310.19 116.376 310.411 116.759Z"
        fill="#13294B"
      />
      <path
        opacity="0.7"
        d="M310.411 116.759L311.744 119.039C311.744 119.039 312.759 122.026 314.8 122.573L320.34 120.783L320.055 120.316C319.348 119.244 319.428 117.158 319.667 115.425C319.907 113.692 319.018 113.635 318.356 114.068C317.888 114.607 317.559 115.253 317.399 115.949C317.127 115.573 316.826 115.218 316.498 114.889L314.811 113.202C314.457 112.856 313.985 112.657 313.491 112.644C312.996 112.631 312.515 112.806 312.143 113.134L310.775 114.353C310.451 114.653 310.238 115.055 310.172 115.492C310.105 115.929 310.19 116.376 310.411 116.759Z"
        fill="white"
      />
      <path
        d="M362.144 108.551C355.998 110.18 350.112 112.662 344.656 115.926C343.805 116.459 343.137 117.239 342.742 118.162C342.346 119.085 342.243 120.107 342.444 121.091C344.645 131.852 349.649 146.262 355.053 155.439L380.258 145.008C380.429 140.562 374.307 126.175 368.072 112.267C366.954 109.77 364.8 107.912 362.144 108.551Z"
        fill="#13294B"
      />
      <path
        opacity="0.8"
        d="M362.144 108.551C355.998 110.18 350.112 112.662 344.656 115.926C343.805 116.459 343.137 117.239 342.742 118.162C342.346 119.085 342.243 120.107 342.444 121.091C344.645 131.852 349.649 146.262 355.053 155.439L380.258 145.008C380.429 140.562 374.307 126.175 368.072 112.267C366.954 109.77 364.8 107.912 362.144 108.551Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M371.982 121.079L366.977 119.404C368.117 122.333 372.141 126.038 374.957 128.216C374.045 125.936 373.031 123.542 371.982 121.079Z"
        fill="#13294B"
      />
      <path
        d="M360.491 97.2648C358.405 93.2976 353.902 91.3026 348.498 91.7472C343.938 92.1348 339.902 96.786 340.381 99.294C340.86 101.802 344.69 102.874 345.42 103.74L342.262 106.02C341.888 106.29 341.573 106.633 341.335 107.028C341.096 107.423 340.941 107.862 340.877 108.319C340.813 108.776 340.842 109.241 340.963 109.687C341.083 110.132 341.293 110.548 341.578 110.911C342.912 112.598 344.668 114.331 345.682 115.607C354.415 115.379 360.878 112.051 363.215 108.847C362.338 104.789 362.52 101.209 360.491 97.2648Z"
        fill="#13294B"
      />
      <path
        opacity="0.8"
        d="M360.491 97.2648C358.405 93.2976 353.902 91.3026 348.498 91.7472C343.938 92.1348 339.902 96.786 340.381 99.294C340.86 101.802 344.69 102.874 345.42 103.74L342.262 106.02C341.888 106.29 341.573 106.633 341.335 107.028C341.096 107.423 340.941 107.862 340.877 108.319C340.813 108.776 340.842 109.241 340.963 109.687C341.083 110.132 341.293 110.548 341.578 110.911C342.912 112.598 344.668 114.331 345.682 115.607C354.415 115.379 360.878 112.051 363.215 108.847C362.338 104.789 362.52 101.209 360.491 97.2648Z"
        fill="white"
      />
      <path
        d="M356.204 99.7271C356.824 101.317 356.959 103.055 356.591 104.721C356.224 106.388 355.37 107.908 354.139 109.089C352.908 110.271 351.354 111.06 349.674 111.359C347.994 111.657 346.263 111.451 344.7 110.766C343.137 110.081 341.812 108.948 340.893 107.51C339.974 106.072 339.502 104.394 339.536 102.688C339.571 100.982 340.11 99.3246 341.086 97.925C342.063 96.5254 343.432 95.4467 345.021 94.8251C347.154 93.9941 349.53 94.0436 351.627 94.9626C353.724 95.8817 355.37 97.5953 356.204 99.7271Z"
        fill="#263238"
      />
      <path
        d="M430.224 202.464C430.099 198.713 429.928 199.044 429.825 195.886C429.757 193.948 429.746 191.794 429.734 189.935C429.73 189.053 429.422 188.199 428.861 187.518C428.3 186.836 427.522 186.369 426.656 186.196C425.151 185.888 423.635 185.603 422.096 185.284C420.124 184.862 418.175 184.406 416.248 183.848C414.743 183.415 413.261 182.936 411.791 182.4C410.32 181.864 408.667 181.26 407.139 180.553C405.338 179.789 403.56 178.969 401.804 178.114C399.832 177.179 397.883 176.198 395.933 175.207C388.432 162.792 388.272 154.607 380.224 145.008L362.942 152.828C362.942 152.828 375.641 175.355 384.282 184.908C389.264 190.403 397.073 192.888 404.039 194.689C409.066 195.977 414.162 196.969 419.258 197.915C421.241 198.28 423.396 198.417 425.106 199.603C426.371 200.573 427.239 201.971 427.545 203.536C427.621 203.855 427.69 204.174 427.751 204.493C427.967 205.451 430.304 204.721 430.224 202.464Z"
        fill="#13294B"
      />
      <path
        opacity="0.8"
        d="M430.224 202.464C430.099 198.713 429.928 199.044 429.825 195.886C429.757 193.948 429.746 191.794 429.734 189.935C429.73 189.053 429.422 188.199 428.861 187.518C428.3 186.836 427.522 186.369 426.656 186.196C425.151 185.888 423.635 185.603 422.096 185.284C420.124 184.862 418.175 184.406 416.248 183.848C414.743 183.415 413.261 182.936 411.791 182.4C410.32 181.864 408.667 181.26 407.139 180.553C405.338 179.789 403.56 178.969 401.804 178.114C399.832 177.179 397.883 176.198 395.933 175.207C388.432 162.792 388.272 154.607 380.224 145.008L362.942 152.828C362.942 152.828 375.641 175.355 384.282 184.908C389.264 190.403 397.073 192.888 404.039 194.689C409.066 195.977 414.162 196.969 419.258 197.915C421.241 198.28 423.396 198.417 425.106 199.603C426.371 200.573 427.239 201.971 427.545 203.536C427.621 203.855 427.69 204.174 427.751 204.493C427.967 205.451 430.304 204.721 430.224 202.464Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M421.458 185.136C420.774 184.988 420.09 184.84 419.395 184.669C419.452 188.613 417.605 195.407 416.932 197.471L419.098 197.881C420.518 193.775 421.313 189.478 421.458 185.136Z"
        fill="#13294B"
      />
      <path
        opacity="0.3"
        d="M429.78 189.935C429.777 189.055 429.47 188.202 428.911 187.521C428.353 186.84 427.576 186.372 426.713 186.196L425.573 185.98C425.756 187.276 426.42 188.455 427.431 189.286C428.082 189.887 428.862 190.332 429.711 190.585C429.78 190.38 429.78 190.152 429.78 189.935Z"
        fill="#13294B"
      />
      <path
        d="M354.597 99.7956C355.053 101.528 353.115 103.341 351.576 101.848C350.14 100.307 348.568 98.8996 346.879 97.641C345.295 96.6492 347.404 94.9164 349.9 95.589C350.966 95.8566 351.95 96.3794 352.768 97.1123C353.586 97.8451 354.214 98.766 354.597 99.7956Z"
        fill="white"
      />
      <path
        d="M354.722 154.88C353.924 155.177 355.383 156.545 355.383 156.545C355.383 156.545 371.343 151.084 381.033 145.464C381.057 145.124 380.999 144.783 380.864 144.469C380.729 144.156 380.521 143.88 380.258 143.663C371.965 147.881 363.439 151.627 354.722 154.88Z"
        fill="#13294B"
      />
      <path
        opacity="0.5"
        d="M354.722 154.88C353.924 155.177 355.383 156.545 355.383 156.545C355.383 156.545 371.343 151.084 381.033 145.464C381.057 145.124 380.999 144.783 380.864 144.469C380.729 144.156 380.521 143.88 380.258 143.663C371.965 147.881 363.439 151.627 354.722 154.88Z"
        fill="white"
      />
      <path
        d="M366.464 107.798C369.611 109.394 372.563 111.07 375.584 112.928C377.055 113.863 378.514 114.809 379.962 115.835C381.41 116.861 382.846 117.91 384.294 119.096L384.83 119.54L385.502 120.156C385.903 120.515 386.284 120.895 386.642 121.296C387.007 121.695 387.315 122.083 387.611 122.436C387.908 122.789 388.227 123.211 388.489 123.576C389.552 125.042 390.533 126.564 391.43 128.136C393.197 131.185 394.722 134.368 395.99 137.655C396.225 138.276 396.237 138.959 396.025 139.588C395.812 140.217 395.389 140.753 394.826 141.105C394.263 141.457 393.595 141.604 392.937 141.519C392.278 141.435 391.67 141.125 391.214 140.642L391.157 140.573C388.877 138.145 386.677 135.592 384.465 133.209C382.253 130.826 380.008 128.41 378.149 127.349C375.562 125.742 372.677 124.135 369.816 122.561L361.198 117.773C359.861 117.066 358.861 115.857 358.416 114.411C357.971 112.966 358.119 111.403 358.826 110.067C359.534 108.731 360.743 107.73 362.189 107.285C363.634 106.841 365.196 106.988 366.533 107.696L366.464 107.798Z"
        fill="#13294B"
      />
      <path
        opacity="0.8"
        d="M366.464 107.798C369.611 109.394 372.563 111.07 375.584 112.928C377.055 113.863 378.514 114.809 379.962 115.835C381.41 116.861 382.846 117.91 384.294 119.096L384.83 119.54L385.502 120.156C385.903 120.515 386.284 120.895 386.642 121.296C387.007 121.695 387.315 122.083 387.611 122.436C387.908 122.789 388.227 123.211 388.489 123.576C389.552 125.042 390.533 126.564 391.43 128.136C393.197 131.185 394.722 134.368 395.99 137.655C396.225 138.276 396.237 138.959 396.025 139.588C395.812 140.217 395.389 140.753 394.826 141.105C394.263 141.457 393.595 141.604 392.937 141.519C392.278 141.435 391.67 141.125 391.214 140.642L391.157 140.573C388.877 138.145 386.677 135.592 384.465 133.209C382.253 130.826 380.008 128.41 378.149 127.349C375.562 125.742 372.677 124.135 369.816 122.561L361.198 117.773C359.861 117.066 358.861 115.857 358.416 114.411C357.971 112.966 358.119 111.403 358.826 110.067C359.534 108.731 360.743 107.73 362.189 107.285C363.634 106.841 365.196 106.988 366.533 107.696L366.464 107.798Z"
        fill="white"
      />
      <path
        d="M398.692 143.344L397.723 140.915C397.723 140.915 397.187 137.815 395.261 136.948L389.561 137.849L389.755 138.35C390.279 139.49 389.88 141.577 389.367 143.241C388.854 144.905 389.72 145.122 390.45 144.791C390.86 144.609 391.259 143.868 391.693 143.081C391.905 143.478 392.145 143.859 392.411 144.221L393.813 146.148C394.106 146.547 394.539 146.821 395.025 146.914C395.512 147.007 396.015 146.912 396.435 146.65L397.985 145.658C398.348 145.409 398.616 145.045 398.744 144.624C398.873 144.204 398.854 143.752 398.692 143.344Z"
        fill="#13294B"
      />
      <path
        opacity="0.8"
        d="M398.692 143.344L397.723 140.915C397.723 140.915 397.187 137.815 395.261 136.948L389.561 137.849L389.755 138.35C390.279 139.49 389.88 141.577 389.367 143.241C388.854 144.905 389.72 145.122 390.45 144.791C390.86 144.609 391.259 143.868 391.693 143.081C391.905 143.478 392.145 143.859 392.411 144.221L393.813 146.148C394.106 146.547 394.539 146.821 395.025 146.914C395.512 147.007 396.015 146.912 396.435 146.65L397.985 145.658C398.348 145.409 398.616 145.045 398.744 144.624C398.873 144.204 398.854 143.752 398.692 143.344Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M361.654 120.908L360.263 121.022L351.724 141.634C352.191 141.667 352.66 141.629 353.115 141.52C353.115 141.52 364.241 137.37 367.604 135.82C365.347 131.157 361.654 120.908 361.654 120.908Z"
        fill="#13294B"
      />
      <path
        d="M346.07 126.312C347.107 131.7 349.034 136.878 351.77 141.634C355.942 140.197 362.896 137.484 366.271 135.934C364.003 131.071 361.99 126.094 360.24 121.022C356.672 121.262 348.84 124.26 346.07 126.312Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M354.54 130.769C354.373 131.327 354.045 131.822 353.597 132.193C353.148 132.564 352.6 132.794 352.021 132.853C351.442 132.912 350.859 132.798 350.345 132.526C349.831 132.253 349.409 131.835 349.133 131.322C348.857 130.81 348.739 130.228 348.794 129.648C348.849 129.069 349.075 128.519 349.443 128.068C349.81 127.617 350.303 127.286 350.86 127.115C351.416 126.944 352.01 126.943 352.568 127.11C353.314 127.334 353.941 127.845 354.311 128.532C354.681 129.218 354.763 130.023 354.54 130.769Z"
        fill="#13294B"
      />
      <path
        opacity="0.6"
        d="M356.717 126.848C356.673 127.038 356.572 127.21 356.428 127.342C356.284 127.474 356.103 127.56 355.909 127.587C355.716 127.614 355.519 127.583 355.344 127.496C355.168 127.409 355.024 127.271 354.928 127.101C354.833 126.93 354.791 126.735 354.809 126.54C354.826 126.346 354.902 126.161 355.027 126.01C355.151 125.86 355.318 125.75 355.506 125.696C355.694 125.642 355.893 125.646 356.079 125.708C356.31 125.782 356.504 125.94 356.623 126.152C356.741 126.364 356.775 126.612 356.717 126.848Z"
        fill="#13294B"
      />
      <path
        opacity="0.6"
        d="M359.271 125.856C359.244 125.988 359.19 126.113 359.112 126.224C359.034 126.334 358.934 126.426 358.818 126.496C358.703 126.565 358.574 126.609 358.44 126.625C358.306 126.642 358.17 126.63 358.041 126.591C357.912 126.552 357.792 126.487 357.69 126.399C357.587 126.311 357.505 126.202 357.447 126.081C357.388 125.959 357.357 125.826 357.353 125.691C357.349 125.556 357.373 125.422 357.424 125.297C357.461 125.177 357.521 125.064 357.601 124.967C357.681 124.87 357.779 124.789 357.89 124.73C358.001 124.67 358.123 124.634 358.249 124.621C358.374 124.609 358.501 124.622 358.621 124.659C358.865 124.733 359.07 124.9 359.192 125.124C359.314 125.348 359.342 125.611 359.271 125.856Z"
        fill="#13294B"
      />
      <path
        opacity="0.5"
        d="M362.85 134.737L352.305 138.898L351.678 136.823L362.212 132.662L362.85 134.737Z"
        fill="#13294B"
      />
      <path
        opacity="0.2"
        d="M304.676 293.174C304.652 299.812 304.011 306.433 302.761 312.953C301.416 318.812 299.326 323.099 296.491 325.812C295.171 327.123 293.603 328.158 291.879 328.856C290.154 329.555 288.308 329.904 286.448 329.882C280.588 329.882 276.116 326.686 273.03 320.294C272.488 319.146 272.027 317.962 271.651 316.749C265.134 322.346 259.066 328.444 253.502 334.989L247.448 342.125C250.706 345.427 254.465 348.194 258.586 350.322C265.685 353.993 275.002 355.832 286.539 355.84C288.568 355.84 290.529 355.783 292.433 355.669C299.413 345.341 305.188 334.249 309.647 322.609L326.576 278.468L304.676 293.174Z"
        fill="black"
      />
      <path
        d="M152.065 353.4L172.015 373.35L227.875 320.716C208.62 313.363 171.183 334.237 152.065 353.4Z"
        fill="#263238"
      />
      <path
        d="M221.639 422.94C215.266 416.556 201.689 402.99 201.689 402.99L254.323 347.13C261.63 366.339 240.757 403.811 221.639 422.94Z"
        fill="#263238"
      />
      <path
        d="M297.996 297.198L319.759 255.235L277.795 277.02C264.316 284.01 251.871 292.834 240.814 303.24L169.279 370.58L204.425 405.726L271.765 334.191C282.175 323.131 291.003 310.682 297.996 297.198Z"
        fill="#13294B"
      />
      <path
        opacity="0.6"
        d="M297.996 297.198L319.759 255.235L277.795 277.02C264.316 284.01 251.871 292.834 240.814 303.24L169.279 370.58L204.425 405.726L271.765 334.191C282.175 323.131 291.003 310.682 297.996 297.198Z"
        fill="white"
      />
      <path
        d="M253.308 336.391C261.43 336.391 268.014 329.807 268.014 321.685C268.014 313.563 261.43 306.979 253.308 306.979C245.186 306.979 238.602 313.563 238.602 321.685C238.602 329.807 245.186 336.391 253.308 336.391Z"
        fill="white"
      />
      <path
        d="M253.308 331.136C258.527 331.136 262.759 326.905 262.759 321.685C262.759 316.466 258.527 312.235 253.308 312.235C248.089 312.235 243.857 316.466 243.857 321.685C243.857 326.905 248.089 331.136 253.308 331.136Z"
        fill="#13294B"
      />
      <path
        opacity="0.3"
        d="M216.315 393.095L181.898 358.678L210.079 332.162L242.831 364.925L216.315 393.095Z"
        fill="#13294B"
      />
      <path
        d="M159.851 384.887C134.087 386.973 125.548 403.469 122.63 425.391C121.148 436.574 120.487 447.895 111.104 454.438C110.54 454.822 110.115 455.378 109.893 456.023C109.671 456.669 109.665 457.369 109.874 458.018C110.083 458.668 110.497 459.232 111.054 459.627C111.611 460.021 112.28 460.225 112.963 460.207C147.55 459.124 163.681 442.195 165.71 435.127C165.178 438.821 164.225 442.443 162.872 445.922C162.623 446.536 162.574 447.213 162.733 447.856C162.891 448.5 163.248 449.076 163.754 449.505C164.259 449.933 164.887 450.191 165.547 450.241C166.207 450.292 166.867 450.133 167.432 449.787C177.133 443.711 189.308 432.516 190.118 414.356C182.981 404.244 159.851 384.887 159.851 384.887Z"
        fill="#13294B"
      />
      <path
        opacity="0.2"
        d="M159.851 384.887C134.087 386.973 125.548 403.469 122.63 425.391C121.148 436.574 120.487 447.895 111.104 454.438C110.54 454.822 110.115 455.378 109.893 456.023C109.671 456.669 109.665 457.369 109.874 458.018C110.083 458.668 110.497 459.232 111.054 459.627C111.611 460.021 112.28 460.225 112.963 460.207C147.55 459.124 163.681 442.195 165.71 435.127C165.178 438.821 164.225 442.443 162.872 445.922C162.623 446.536 162.574 447.213 162.733 447.856C162.891 448.5 163.248 449.076 163.754 449.505C164.259 449.933 164.887 450.191 165.547 450.241C166.207 450.292 166.867 450.133 167.432 449.787C177.133 443.711 189.308 432.516 190.118 414.356C182.981 404.244 159.851 384.887 159.851 384.887Z"
        fill="white"
      />
      <path
        d="M194.119 422.142L152.851 380.874L174.83 376.12L198.873 400.163L194.119 422.142Z"
        fill="#13294B"
      />
    </svg>
  );
};
