import React, { useState } from "react";
import { InputLabel, Tooltip } from "../../common";
var NumberField = function (_a) {
    var className = _a.className, _b = _a.caption, caption = _b === void 0 ? "" : _b, _c = _a.captionType, captionType = _c === void 0 ? "default" : _c, id = _a.id, name = _a.name, value = _a.value, label = _a.label, labelCaption = _a.labelCaption, toolTip = _a.toolTip, toolTipPosition = _a.toolTipPosition, width = _a.width, _d = _a.maxLength, maxLength = _d === void 0 ? 10 : _d, _e = _a.placeholder, placeholder = _e === void 0 ? "" : _e, _f = _a.isDecimal, isDecimal = _f === void 0 ? false : _f, _g = _a.disabled, disabled = _g === void 0 ? false : _g, onInput = _a.onInput, onBlur = _a.onBlur;
    var _h = useState(false), isFocus = _h[0], setIsFocus = _h[1];
    var onChangeHandler = function (e) {
        if (!onInput)
            return;
        var value = e.target.value;
        var numberRegex = isDecimal ? /^[0-9]*\.?([0-9]{1,2})?$/ : /^[0-9]*$/;
        if ((value.length === 0 || numberRegex.test(value)) &&
            value.length <= maxLength) {
            onInput(value);
        }
        return;
    };
    var isColumnAlign = className && className.includes("flex-col");
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: className },
            !!label && (React.createElement("div", { className: "flex ".concat(isColumnAlign ? "" : "h-9", " items-center gap-1 w-full") },
                React.createElement(InputLabel, { id: id || name, label: label }),
                !!toolTip && React.createElement(Tooltip, { text: toolTip, position: toolTipPosition }))),
            labelCaption && (React.createElement("div", { className: " text-xs font-inter font-normal text-blue-152 opacity-60" }, labelCaption)),
            React.createElement("div", { className: "flex-1" },
                React.createElement("div", { className: "flex border text-xs rounded-lg w-full items-center relative p-2 bg-white h-9 \n            ".concat(width ? "" : "flex-1", " ").concat(disabled ? "bg-neutrals-EAE" : "bg-white", "\n            ").concat(isFocus
                        ? "ring-1 ring-blue-132"
                        : captionType === "error"
                            ? "ring-1 ring-red-FD0"
                            : "border-neutrals-D9D", "\n            "), style: { width: width || "auto" } },
                    React.createElement("input", { id: id || name, name: name, value: value, onChange: onChangeHandler, className: "flex-grow flex-shrink font-inter flex-1 min-w-0 outline-none bg-opacity-0 bg-transparent placeholder:text-neutrals-D9D disabled:text-neutrals-D9D", autoComplete: "off", disabled: disabled, placeholder: placeholder, type: "text", onFocus: function () { return setIsFocus(true); }, onBlur: function () {
                            setIsFocus(false);
                            onBlur && onBlur();
                        } })),
                caption.length > 0 && (React.createElement("div", { className: "text-xs ".concat(captionsTypeMap[captionType], " mt-1 font-inter opacity-80") }, caption))))));
};
var captionsTypeMap = {
    success: "text-green-47D",
    default: "text-blue-152",
    error: "text-red-FD0",
};
export { NumberField };
