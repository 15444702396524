import React, { useContext } from "react";
import { GlobalContextAPI } from "../../context/GlobalContext";
import { CheckCircle, CrossX, CircleMinus } from "../Icons";
export var Snackbar = function (_a) {
    var _b;
    var onClose = _a.onClose;
    var snackbar = useContext(GlobalContextAPI).snackbar;
    var renderIcon = function (type) {
        if (type === "success")
            return (React.createElement("div", { className: "text-green-02E" },
                React.createElement(CheckCircle, null)));
        else
            return (React.createElement("div", { className: "text-red-FD0" },
                React.createElement(CircleMinus, null)));
    };
    return (React.createElement("div", { className: "h-full w-full flex justify-center items-end fixed bottom-10 z-50" },
        React.createElement("div", { className: "flex bg-blue-343 rounded-xl p-2 justify-between h-14 w-1/3" },
            React.createElement("div", { className: "flex items-center gap-2" },
                renderIcon(snackbar.item.type),
                React.createElement("div", { className: "text-white text-sm font-inter font-medium" }, (_b = snackbar.item) === null || _b === void 0 ? void 0 : _b.message)),
            React.createElement("div", { className: "text-neutrals-7B7 cursor-pointer", onClick: function () { return onClose(); }, role: "closeSnackbar" },
                React.createElement(CrossX, null)))));
};
