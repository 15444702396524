// import { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import RouterLayout from "./routes";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useContext, useEffect } from "react";
import { GlobalContextAPI } from "./context/GlobalContext";
import { UseDesktopWrapper } from "./components/layouts/Wrappers/UseDektopWrapper";
import { isDesktop } from "react-device-detect";
import { useDispatch } from "react-redux";
import { clearErrorPerMinutesData } from "./redux/Reducers/Reporting/Singledashboard/ErrorPerMinutesReducer";
import { clearThroughputData } from "./redux/Reducers/Reporting/Singledashboard/ThroughputReducer";
import { clearTransactionResponseTimeData } from "./redux/Reducers/Reporting/Singledashboard/TransactionResponseTimeReducer";
import { clearVusersData } from "./redux/Reducers/Reporting/Singledashboard/RunningVUsersReducer";
function App() {
  const dispatcher = useDispatch();
  // const [isDesktop, setIsDesktop] = useState(true);
  const { snackbar } = useContext(GlobalContextAPI);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        onError: (error) => (
          <>
            {snackbar.handler({
              message: (
                <>
                  {error?.response?.data?.message ||
                    error?.message ||
                    "Something went wrong!"}
                </>
              ),
              type: "error",
              value: true,
              timeoutTimer: 3000,
            })}
          </>
        ),
        retry: false,
      },
    },
  });

  useEffect(() => {
    // add below on logout
    /**remove following localstorage data
     * persist:root
     */

    //clear redux reporting data
    localStorage.clear("persist:root");
    dispatcher(clearErrorPerMinutesData());
    dispatcher(clearThroughputData());
    dispatcher(clearTransactionResponseTimeData());
    dispatcher(clearVusersData());

    localStorage.removeItem("navigationAllowed");
  }, []);

  // useEffect(() => {
  //   const checkWidth = () =>
  //     setTimeout(() => {
  //       if (window.innerWidth < 768) {
  //         setIsDesktop(false);
  //       } else {
  //         setIsDesktop(true);
  //       }
  //     }, 100);
  //   checkWidth();
  //   window.addEventListener("resize", checkWidth);

  //   return () => window.removeEventListener("resize", setIsDesktop(true));
  // }, []);
  if (!isDesktop) {
    return <UseDesktopWrapper></UseDesktopWrapper>;
  }

  return (
    <div className="w-full h-full overflow-hidden relative">
      <QueryClientProvider client={queryClient}>
        <Router>
          <RouterLayout />
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </div>
  );
}

export default App;
