import React from "react";
import { Info } from "../Icons";
export var Tooltip = function (_a) {
    var text = _a.text, _b = _a.position, position = _b === void 0 ? "top" : _b, _c = _a.withIcon, withIcon = _c === void 0 ? true : _c;
    return (React.createElement("div", { className: "text-blue-152 relative group" },
        React.createElement("span", { className: "text-xxs bg-blue-343 text-white font-inter p-1 rounded hidden absolute \n        z-10 w-80 group-hover:block after:content-[''] \n        after:absolute after:border-4 ".concat(tooltipPositionMap[position], "\n      ") }, text),
        withIcon && (React.createElement("div", { className: "" },
            React.createElement(Info, null)))));
};
var tooltipPositionMap = {
    top: "bottom-5  after:top-full after:left-3 after:-ml-2 after:border-t-blue-343 after:border-x-transparent after:border-b-transparent",
    bottom: "top-5 -left-24 after:-top-2 after:left-1/3 after:-ml-2 after:border-t-transparent after:border-x-transparent after:border-b-blue-343",
    right: "-top-1 left-5 after:top-2 after:left-0 after:-ml-2 after:border-r-blue-343 after:border-y-transparent after:border-l-transparent",
};
