export const PlusCircle = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 7C11.8188 7.00001 11.6437 7.06564 11.5071 7.18477C11.3705 7.30389 11.2816 7.46845 11.257 7.648L11.25 7.75V11.25H7.75C7.55998 11.2501 7.37706 11.3222 7.23821 11.452C7.09936 11.5817 7.01493 11.7593 7.00197 11.9489C6.98902 12.1385 7.04852 12.3259 7.16843 12.4733C7.28835 12.6207 7.45975 12.7171 7.648 12.743L7.75 12.75H11.25V16.25C11.2501 16.44 11.3222 16.6229 11.452 16.7618C11.5817 16.9006 11.7593 16.9851 11.9489 16.998C12.1385 17.011 12.3259 16.9515 12.4733 16.8316C12.6207 16.7117 12.7171 16.5402 12.743 16.352L12.75 16.25V12.75H16.25C16.44 12.7499 16.6229 12.6778 16.7618 12.548C16.9006 12.4183 16.9851 12.2407 16.998 12.0511C17.011 11.8615 16.9515 11.6741 16.8316 11.5267C16.7117 11.3793 16.5402 11.2829 16.352 11.257L16.25 11.25H12.75V7.75C12.75 7.55109 12.671 7.36032 12.5303 7.21967C12.3897 7.07902 12.1989 7 12 7Z"
        fill="currentColor"
      />
    </svg>
  );
};
